import {http, post} from "@/utils/request";
export function getLangTitle(lang: string) {
  return post('/main/anonymous/lang/getLangTitle', {
    module: 'setup',
  }, {
    baseURL: '/api',
    headers: {
      lang
    }
  });
}
export function getMetaLangList(data?: unknown) {
  return post('/main/anonymous/lang/enable/list', data, {
    baseURL: '/api'
  });
}

export function updateLang(data?: unknown) {
  return post('/auth/setup/user/update/tenant/lang', data, {
    baseURL: '/api'
  });
}
