import { defineComponent as _defineComponent } from "vue";
import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createElementVNode as _createElementVNode, mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = (n) => (_pushScopeId("data-v-1ad8989a"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "app-store-dialog" };
const _hoisted_2 = /* @__PURE__ */ _withScopeId(() => /* @__PURE__ */ _createElementVNode("div", { class: "query-search mt-6" }, [
  /* @__PURE__ */ _createElementVNode("button", { class: "query-button active rounded-full py-2 px-6" }, "\u5168\u90E8\u5E94\u7528"),
  /* @__PURE__ */ _createElementVNode("button", { class: "query-button rounded-full py-1 px-6" }, "\u6761\u4EF6\u4E00")
], -1));
const _hoisted_3 = { class: "apps-content mt-6" };
const _hoisted_4 = { class: "grid 2xl:grid-cols-4 xl:grid-cols-3 gap-x-1 gap-y-4 grid-cols-2 py-2" };
import { ref } from "vue";
import { Search } from "@element-plus/icons-vue";
import Item from "./item.vue";
export default /* @__PURE__ */ _defineComponent({
  __name: "dialog",
  setup(__props, { expose: __expose }) {
    const visible = ref(false);
    const open = () => {
      visible.value = true;
    };
    const close = () => {
      visible.value = false;
    };
    __expose({ open, close });
    return (_ctx, _cache) => {
      const _component_el_icon = _resolveComponent("el-icon");
      const _component_el_input = _resolveComponent("el-input");
      const _component_el_scrollbar = _resolveComponent("el-scrollbar");
      const _component_el_dialog = _resolveComponent("el-dialog");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_el_dialog, _mergeProps({
          modelValue: visible.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => visible.value = $event),
          title: "\u5E94\u7528\u5E02\u573A"
        }, _ctx.$attrs, {
          width: "60%",
          "close-on-click-modal": false
        }), {
          default: _withCtx(() => [
            _createVNode(_component_el_input, {
              style: { "width": "100%" },
              size: "large"
            }, {
              suffix: _withCtx(() => [
                _createVNode(_component_el_icon, { size: 20 }, {
                  default: _withCtx(() => [
                    _createVNode(_unref(Search), {
                      style: { "margin-top": "10px", "right": "5px" },
                      class: "relative"
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _hoisted_2,
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_el_scrollbar, { "max-height": "400px" }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_4, [
                    _createVNode(Item),
                    _createVNode(Item),
                    _createVNode(Item),
                    _createVNode(Item),
                    _createVNode(Item),
                    _createVNode(Item),
                    _createVNode(Item),
                    _createVNode(Item)
                  ])
                ]),
                _: 1
              })
            ])
          ]),
          _: 1
        }, 16, ["modelValue"])
      ]);
    };
  }
});
