import {reactive, computed} from 'vue'

export function useCountDown(timmer: number, onEnd: Function) {
    const state = reactive({
        timmer,
        state: 0
    })

    const _call = () => {
        let t = setTimeout(() => {
            if (state.timmer > 0) {
                state.timmer -= 1000
                clearTimeout(t)
            }
            if (state.timmer <= 0) {
                end()
            } else {
                _call()
            }
        }, 1000)

    }

    function start() {
        state.state = 1
        _call()
    }

    function end() {
        state.state = 0
        state.timmer = timmer
        console.log('---end ')
        onEnd()
    }

    const toSecond = computed(() => (state.timmer / 1000))

    const ctx = {start, end, toSecond}
    const res = [state as typeof state, ctx]
    return res

}
