import { defineComponent } from "vue";
import AppHeaderDefault from "@/components/AppHeader/default.vue";
import Indroduce from "./components/sections/Indroduce.vue";
import ProductIndroduce from "./components/sections/ProductIndroduce.vue";
import UserCase from "./components/sections/UserCase.vue";
import AppFooter from "@/components/AppFooter/index.vue";
import Language from "@/components/Language/index.vue";
import { useAuthStore } from "@/store/modules/auth";
import { useI18nTransform } from "@/hooks/useI18nTransform";
export default defineComponent({
  name: "Home",
  components: {
    AppHeaderDefault,
    Indroduce,
    ProductIndroduce,
    UserCase,
    Language,
    AppFooter
  },
  setup() {
    const { t } = useI18nTransform();
    const authStore = useAuthStore();
    return { t, authStore };
  }
});
