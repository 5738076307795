
// libs
import {createApp} from 'vue'
import '@/../node_modules/modern-normalize/modern-normalize.css';
import '@/assets/styles/index.css'
import '@/assets/styles/tailwind.css'
import '@/assets/styles/common.css'
import '@/assets/styles/ckeditor.css'
import 'animate.css'
import 'swiper/swiper-bundle.css'
// import 'element-plus/dist/index.css'
import store from '@/store'
import {setup as pluginSetup} from '@/plugins'
import i18n from '@/i18n'
import Icon from '@/components/Icon'
import App from './App.vue'
import router from './router'
import VueLazyload from 'vue-lazyload'
import ElementPlus from 'element-plus'

const app = createApp(App)

app.use(ElementPlus)
pluginSetup(app)
app.component('svg-icon', Icon)
app.use(i18n).use(VueLazyload).use(router).use(store).mount('#app')
