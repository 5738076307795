import Components from './components'
import Cybercloud from './cybercloud'
import Directives from '../directives'
import GlobalInterface from './globalInterface'

import {App} from 'vue'

const plugins = [
    Components,
    Cybercloud,
    Directives,
    GlobalInterface,
]

export function setup(app: App) {
    plugins.forEach(plugin => {
        plugin(app)
    })
}
