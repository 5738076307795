import { defineComponent as _defineComponent } from "vue";
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, unref as _unref, withCtx as _withCtx, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = (n) => (_pushScopeId("data-v-e0c5d018"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "setting-info" };
const _hoisted_2 = { class: "info-label" };
const _hoisted_3 = ["innerHTML"];
const _hoisted_4 = {
  key: 1,
  class: "info-content__wrap"
};
const _hoisted_5 = {
  key: 1,
  class: "info-text text-hide"
};
const _hoisted_6 = {
  key: 0,
  class: "info-editor"
};
import { ref, computed } from "vue";
import { ElButton, ElOption, ElRadioGroup, ElRadio } from "element-plus";
import { useI18nTransform } from "@/hooks/useI18nTransform";
export default /* @__PURE__ */ _defineComponent({
  __name: "CommonInfo",
  props: {
    label: String,
    content: String,
    html: String,
    command: String,
    disableCommand: {
      type: Boolean,
      default: false
    },
    type: String,
    loading: Boolean,
    labelWidth: {
      type: [String, Number]
    },
    hasBackground: Boolean,
    options: {
      type: Array,
      default: null
    }
  },
  emits: ["update:content", "edit", "confirm"],
  setup(__props, { emit }) {
    const props = __props;
    const { t } = useI18nTransform();
    const value = ref(props.content);
    const isUpdate = ref(false);
    const text = computed(() => {
      if (props.options) {
        const i = props.options.find((i2) => i2.value === props.content);
        if (i)
          return i.label;
      }
      return props.content;
    });
    function handleEdit() {
      if (props.type === "custom") {
        emit("edit");
        return;
      }
      isUpdate.value = true;
      value.value = props.content;
    }
    function handleCancel() {
      value.value = props.content;
      isUpdate.value = false;
    }
    function handleOk() {
      emit("update:content", value);
      emit("confirm", value.value, () => {
        isUpdate.value = false;
      });
    }
    return (_ctx, _cache) => {
      const _component_CInput = _resolveComponent("CInput");
      const _component_CSelect = _resolveComponent("CSelect");
      const _directive_tooltip = _resolveDirective("tooltip");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("label", _hoisted_2, _toDisplayString(props.label), 1),
        _createElementVNode("div", {
          class: _normalizeClass(["info-content", { "has-background": props.hasBackground, "is-edit": isUpdate.value }])
        }, [
          props.html ? _withDirectives((_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "info-content__wrap",
            innerHTML: props.html
          }, null, 8, _hoisted_3)), [
            [_directive_tooltip]
          ]) : (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _renderSlot(_ctx.$slots, "default", {}, () => [
              isUpdate.value ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                props.type === "input" ? (_openBlock(), _createBlock(_component_CInput, {
                  key: 0,
                  modelValue: value.value,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => value.value = $event)
                }, null, 8, ["modelValue"])) : _createCommentVNode("", true),
                props.type === "select" ? (_openBlock(), _createBlock(_component_CSelect, {
                  key: 1,
                  modelValue: value.value,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event) => value.value = $event)
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.options, (item) => {
                      return _openBlock(), _createBlock(_unref(ElOption), {
                        key: item.value,
                        value: item.value,
                        label: item.label
                      }, null, 8, ["value", "label"]);
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["modelValue"])) : _createCommentVNode("", true),
                props.type === "radio" ? (_openBlock(), _createBlock(_unref(ElRadioGroup), {
                  key: 2,
                  modelValue: value.value,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event) => value.value = $event)
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.options, (item) => {
                      return _openBlock(), _createBlock(_unref(ElRadio), {
                        key: item.value,
                        label: item.value
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(item.label), 1)
                        ]),
                        _: 2
                      }, 1032, ["label"]);
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["modelValue"])) : _createCommentVNode("", true)
              ], 64)) : _withDirectives((_openBlock(), _createElementBlock("span", _hoisted_5, [
                _createTextVNode(_toDisplayString(text.value), 1)
              ])), [
                [_directive_tooltip]
              ])
            ])
          ]))
        ], 2),
        _renderSlot(_ctx.$slots, "right", {}, () => [
          props.command && !props.disableCommand ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            !isUpdate.value ? (_openBlock(), _createBlock(_unref(ElButton), {
              key: 0,
              loading: __props.loading,
              type: "text",
              onClick: handleEdit
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(props.command), 1)
              ]),
              _: 1
            }, 8, ["loading"])) : _createCommentVNode("", true),
            isUpdate.value ? (_openBlock(), _createBlock(_unref(ElButton), {
              key: 1,
              type: "text",
              onClick: _cache[3] || (_cache[3] = ($event) => handleCancel())
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_unref(t)("\u53D6\u6D88")), 1)
              ]),
              _: 1
            })) : _createCommentVNode("", true),
            isUpdate.value ? (_openBlock(), _createBlock(_unref(ElButton), {
              key: 2,
              type: "text",
              onClick: _cache[4] || (_cache[4] = ($event) => handleOk())
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_unref(t)("\u786E\u8BA4")), 1)
              ]),
              _: 1
            })) : _createCommentVNode("", true)
          ])) : _createCommentVNode("", true)
        ])
      ]);
    };
  }
});
