import { defineComponent as _defineComponent } from "vue";
import { createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, Suspense as _Suspense, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
import _imports_0 from "@/assets/images/logo.png";
import _imports_1 from "@/assets/images/auth_banner1.jpg";
const _withScopeId = (n) => (_pushScopeId("data-v-3e886135"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "regsiter-page overflow-hidden w-screen h-screen flex" };
const _hoisted_2 = {
  class: "left box-border xl:w-6/12 h-full flex flex-col justify-center items-center w-full relative",
  style: { "min-height": "700px" }
};
const _hoisted_3 = {
  class: "logo",
  style: { "margin-top": "-100px", "margin-bottom": "80px" }
};
const _hoisted_4 = {
  class: "form-panel",
  style: { "width": "500px" }
};
const _hoisted_5 = /* @__PURE__ */ _withScopeId(() => /* @__PURE__ */ _createElementVNode("div", { class: "right relative box-border w-6/12 flex-1 bg-cover bg-right bg-no-repeat bg-center justify-center items-center flex hidden xl:flex" }, [
  /* @__PURE__ */ _createElementVNode("div", {
    class: "banner-panel w-10/12 h-full flex items-center",
    style: { "background-color": "#e8f2fd", "width": "100%" }
  }, [
    /* @__PURE__ */ _createElementVNode("img", {
      src: _imports_1,
      class: "absolute",
      style: { "right": "7.5%", "width": "95%" }
    })
  ])
], -1));
import LoginForm from "./components/forms/login";
import Language from "@/components/Language/index.vue";
export default /* @__PURE__ */ _defineComponent({
  __name: "login",
  setup(__props) {
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(Language, { class: "setting-language" }),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("img", {
              src: _imports_0,
              onClick: _cache[0] || (_cache[0] = ($event) => _ctx.$router.push("/"))
            })
          ]),
          _createElementVNode("div", _hoisted_4, [
            (_openBlock(), _createBlock(_Suspense, null, {
              default: _withCtx(() => [
                _createVNode(_unref(LoginForm))
              ]),
              _: 1
            }))
          ])
        ]),
        _hoisted_5
      ]);
    };
  }
});
