import { defineComponent, watch, ref } from "vue";
import { useRouter, useRoute, useLink } from "vue-router";
import { useI18nTransform } from "@/hooks/useI18nTransform";
import { useI18n } from "vue-i18n";
import getEPLocale from "@/i18n/element-plus";
import { useAppStore } from "@/store/modules/app";
const { t } = useI18nTransform();
export default defineComponent({
  data() {
    return {
      // locale: zhCn,
    };
  },
  setup() {
    const { locale } = useI18n();
    const customLocale = ref(getEPLocale(t));
    const appStore = useAppStore();
    watch(locale, () => {
      document.body.setAttribute("data-lang", locale.value);
      customLocale.value = getEPLocale(t);
    }, {
      immediate: true
    });
    return {
      useRoute,
      useRouter,
      useLink,
      locale,
      customLocale,
      appStore,
      t
    };
  }
});
