import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = (n) => (_pushScopeId("data-v-2684215e"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "copyright" };
const _hoisted_2 = { class: "container m-auto border-t py-4 border-solid text-center text-sm" };
const _hoisted_3 = /* @__PURE__ */ _withScopeId(() => /* @__PURE__ */ _createElementVNode("a", {
  href: "https://beian.miit.gov.cn",
  target: "_Blank"
}, "\u6CAAICP\u590712001270\u53F7-2", -1));
export function render(_ctx, _cache) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createTextVNode(_toDisplayString(_ctx.$t("\u8F6F\u4EF6\u4F01\u4E1A\u7F16\u53F7")) + "\uFF1A\u6CAAR-2014-0180 " + _toDisplayString(_ctx.$t("\u5DE5\u4FE1\u90E8\u5907\u6848")) + "\uFF1A", 1),
      _hoisted_3,
      _createTextVNode(" Copyright \xA92011-2019 \u4E0A\u6D77\u901A\u5929\u6653 " + _toDisplayString(_ctx.$t("\u7248\u6743\u6240\u6709")), 1)
    ])
  ]);
}
