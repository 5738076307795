import { post } from "@/utils/request";

// 获取 nvc 配置
export function configGet() {
  return post("/auth/nvc/config/get");
}

// check
export function nvcCheck(data: any) {
  return post("/auth/nvc/config/check", data);
}
