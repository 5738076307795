import { post } from "@/utils/request";

// 获取子账号列表
export const apiGetSubAccountList = (data) => post('/subAccount/subUserList', data)

// 创建子账号
export const apiCreateSubAccount = (data) => post('/subAccount/createSubUser', {
    ...data,
    status: 1
})

// 更新子账号
export const apiUpdateSubAccount = (data) => post('/subAccount/updateSubUser', {
    ...data,
    status: 1
})

// 移除子账号
export const apiRemoveSubAccount = (data) => post('/subAccount/removeSub', data)

// 重设子账号密码
export const apiResetSubAccountPassword = (data) => post('/subAccount/updateSubUserPwd', data)
