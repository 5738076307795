import {post} from '@/utils/request'
import { NoticeInfo } from '@/types/notice'

// 消息通知

export function getNoticeList(data: any) {
    return post('/tenant/notice/list', data)
}

export function updateNoticeRead(id: string) {
    return post('/tenant/notice/update/read', {
        id
    })
}
export function getNoticeUnreadCount() {
    return post<{ count: number }>('/tenant/notice/unread/count')
}
