var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { defineComponent as _defineComponent } from "vue";
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, withKeys as _withKeys, Fragment as _Fragment, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = (n) => (_pushScopeId("data-v-2fa91c76"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "login-form" };
const _hoisted_2 = { class: "title pb-8 font-medium text-2xl" };
const _hoisted_3 = { class: "flex flex1" };
const _hoisted_4 = { key: 0 };
const _hoisted_5 = /* @__PURE__ */ _withScopeId(() => /* @__PURE__ */ _createElementVNode("div", { id: "nc" }, null, -1));
const _hoisted_6 = { class: "text-sm" };
const _hoisted_7 = { class: "mt-5" };
const _hoisted_8 = { class: "text-sm" };
const _hoisted_9 = { class: "mt-16" };
const _hoisted_10 = { class: "mt-8 flex justify-center items-center" };
const _hoisted_11 = {
  key: 0,
  class: "mt-4 flex justify-center items-center"
};
const _hoisted_12 = { class: "pt-4 text-sm text-gray-500" };
import { useRouter } from "vue-router";
import { reactive, ref, onMounted, watch, nextTick, computed } from "vue";
import { useRouterHelpers } from "@/hooks/useRouterHelpers";
import { useAuthStore } from "@/store/modules/auth";
import { required } from "@/utils/validate";
import { ElMessage } from "element-plus";
import { createStorage } from "@/utils/storage";
import { useNvc } from "@/hooks/useNvc";
import { verifyCodeSend, loginVerify, getLoginKey } from "@/api/auth";
import { ThirdAuthType, VERIFY_MDIFY_CODE } from "@/enums/auth";
import { useThirdAuth } from "@/hooks/useThirdAuth";
import AuthBind from "@/components/Common/AuthBind.vue";
import { useI18nTransform } from "@/hooks/useI18nTransform";
const rememberLoginAccountKey = "REMEMBER_LOGIN_ACCOUNT";
export default /* @__PURE__ */ _defineComponent({
  __name: "login",
  setup(__props, { expose: __expose }) {
    const { t } = useI18nTransform();
    const { loginThird, unbindAccount, loading } = useThirdAuth();
    const loginForm = ref(null);
    const router = useRouter();
    const state = reactive({
      accountType: "main",
      current: 0,
      submitLoading: false,
      loading: false,
      code: "",
      loginType: "",
      countdown: -1,
      isSend: false,
      showBind: false
    });
    const isMainAccountLoginMode = computed(() => state.accountType === "main");
    const isSubAccountLoginMode = computed(() => state.accountType === "sub");
    const toggleAccountType = () => {
      var _a, _b;
      state.accountType = state.accountType === "sub" ? "main" : "sub";
      (_b = (_a = loginForm.value) == null ? void 0 : _a.clearValidate) == null ? void 0 : _b.call(_a);
    };
    const storage = createStorage({ prefixKey: "USER_", storage: window.localStorage });
    const temp = storage.get(rememberLoginAccountKey);
    const usernamePlaceholder = computed(() => {
      return isMainAccountLoginMode.value ? t("\u90AE\u7BB1/\u624B\u673A\u53F7/\u7528\u6237\u540D") : t("\u8D26\u53F7\u540D@\u79DF\u6237\u7F16\u7801");
    });
    const formData = ref({
      account: "",
      password: "",
      // 是否记住当前账号
      remember: Boolean(temp)
    });
    if (temp) {
      formData.value.account = temp.account;
      formData.value.password = temp.password;
    }
    const authStore = useAuthStore();
    const { goEnv, goRegister } = useRouterHelpers();
    function getValue() {
      return new Promise((resolve, reject) => {
        loginForm.value.validate((valid) => {
          if (valid) {
            resolve(__spreadValues({}, formData.value));
          } else
            reject();
        });
      });
    }
    const loginSuccess = (s) => {
      s = s || {
        isVerify: false
      };
      if (!s.isVerify) {
        goEnv();
        ElMessage({ type: "success", message: t("\u767B\u5F55\u6210\u529F") });
      } else {
        router.push({
          path: "/verify",
          query: { vid: s.verifyId, from: "login" }
        });
        ElMessage({ type: "warning", message: t("\u9700\u8981\u8FDB\u884C\u9A8C\u8BC1") });
      }
    };
    const setRemember = () => {
      if (formData.value.remember) {
        storage.set(rememberLoginAccountKey, formData.value);
      } else {
        storage.remove(rememberLoginAccountKey);
      }
    };
    const [nvcLoginState, { call: callLogin, reset: loginNvcReset }] = useNvc((nvc) => {
      state.submitLoading = true;
      return authStore.encrypted(formData.value.password).then((password) => {
        const d = __spreadProps(__spreadValues({}, formData.value), {
          password,
          isSub: isSubAccountLoginMode.value ? true : false,
          nvc: { data: nvc }
        });
        return authStore.login(d).finally(() => {
          state.submitLoading = false;
        });
      });
    }, {
      success(response) {
        setRemember();
        loginSuccess(response);
      }
    });
    const [nvcSendVerifyCodeState, { call: callSendCodeVerify, reset: sendCodeNvcReset }] = useNvc((nvc) => {
      state.loading = true;
      return verifyCodeSend({
        account: formData.value.account,
        bizCode: VERIFY_MDIFY_CODE.LOGIN,
        nvc: { data: nvc }
      }).finally(() => {
        state.loading = false;
      });
    }, {
      success() {
        ElMessage.success(t("\u5DF2\u53D1\u9001\u9A8C\u8BC1\u7801"));
        state.isSend = true;
        state.countdown = 60;
        startCountdown();
      }
    });
    const reset = () => {
      loginNvcReset();
      sendCodeNvcReset();
    };
    watch(() => [
      nvcLoginState.success,
      nvcSendVerifyCodeState.success
    ], () => {
      loginForm.value.clearValidate("afs");
    });
    const loginRules = computed(() => {
      return {
        account: [
          {
            required: true,
            trigger: "change",
            validator: function(r, v, c) {
              if (!(v == null ? void 0 : v.length)) {
                const errMsg = isMainAccountLoginMode.value ? t("\u8BF7\u8F93\u5165\u90AE\u7BB1\u3001\u624B\u673A\u53F7\u3001\u7528\u6237\u540D") : t("\u8BF7\u8F93\u5165\u7528\u6237\u540D@\u79DF\u6237\u7F16\u7801");
                return c(new Error(errMsg));
              }
              if (isMainAccountLoginMode.value) {
                if (!/(^[A-Za-z0-9\u4e00-\u9fa5_-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)+$)|(^1(3|4|5|6|7|8|9)\d{9}$)|(^[a-zA-Z][-_a-zA-Z0-9]{2,49}$)/.test(v)) {
                  return c(new Error(t("\u8BF7\u8F93\u5165\u6B63\u786E\u7684\u90AE\u7BB1\u3001\u624B\u673A\u53F7\u3001\u7528\u6237\u540D")));
                }
              }
              if (isSubAccountLoginMode.value) {
                const matchRes = v.match(/@([^.]+)$/);
                const tenantCode = (matchRes == null ? void 0 : matchRes[1]) || "";
                if (!tenantCode) {
                  return c(new Error(t("\u8BF7\u8F93\u5165\u7528\u6237\u540D@\u79DF\u6237\u7F16\u7801\uFF0C\u683C\u5F0F\u5982\u201Cwang@cybercloud\u201D")));
                }
              }
              return c();
            }
          }
        ],
        password: [
          required(t("\u8BF7\u8F93\u5165\u5BC6\u7801"))
        ],
        afs: [
          {
            validator: function(r, v, c) {
              if (!nvcLoginState.ncValue || !nvcSendVerifyCodeState.ncValue)
                return c(new Error("\u8BF7\u5148\u8FDB\u884C\u6ED1\u52A8\u9A8C\u8BC1"));
              else
                c();
            }
          }
        ]
      };
    });
    const loginWithPhone = () => __async(this, null, function* () {
      if (!state.code) {
        ElMessage.error(t("\u8BF7\u8F93\u5165\u9A8C\u8BC1\u7801"));
        return Promise.reject();
      }
      return loginVerify({
        account: formData.value.account,
        verifyCode: state.code
      }).then((response) => {
        loginSuccess(response);
      });
    });
    const loginWithNVC = () => __async(this, null, function* () {
      try {
        const formData2 = yield getValue();
        callLogin(formData2);
      } catch (e) {
        console.log(e);
      }
    });
    const loginNormal = () => __async(this, null, function* () {
      const password = yield authStore.encrypted(formData.value.password);
      const d = __spreadProps(__spreadValues({}, formData.value), {
        password,
        isSub: isSubAccountLoginMode.value ? true : false
      });
      yield authStore.login(d);
      setRemember();
      loginSuccess();
    });
    const login = () => __async(this, null, function* () {
      state.submitLoading = true;
      try {
        if (state.loginType === "phone") {
          return loginWithPhone();
        }
        if (isSubAccountLoginMode.value) {
          return loginNormal();
        }
        return loginWithNVC();
      } finally {
        state.submitLoading = false;
      }
    });
    const handleChangePhone = () => {
      state.loginType = "phone";
      reset();
    };
    const handleChangeEmail = () => {
      state.loginType = "email";
      reset();
    };
    function getCode() {
      callSendCodeVerify();
    }
    function startCountdown() {
      setTimeout(() => {
        state.countdown--;
        if (state.countdown > 0) {
          startCountdown();
        }
      }, 1e3);
    }
    const loginWechat = () => {
      state.showBind = true;
      nextTick(() => {
        var _a;
        (_a = loginThird(ThirdAuthType.WECHAT)) == null ? void 0 : _a.then((res) => {
          loginSuccess(res);
        }).finally(() => {
          state.showBind = false;
        });
      });
    };
    const loginAlipay = () => {
      var _a;
      (_a = loginThird(ThirdAuthType.ALIPAY)) == null ? void 0 : _a.then((res) => {
        loginSuccess(res);
      });
    };
    onMounted(() => {
      getLoginKey();
    });
    __expose({
      getValue
    });
    return (_ctx, _cache) => {
      const _component_svg_icon = _resolveComponent("svg-icon");
      const _component_el_input = _resolveComponent("el-input");
      const _component_el_form_item = _resolveComponent("el-form-item");
      const _component_ElButton = _resolveComponent("ElButton");
      const _component_el_checkbox = _resolveComponent("el-checkbox");
      const _component_el_button = _resolveComponent("el-button");
      const _component_el_form = _resolveComponent("el-form");
      const _component_ElDivider = _resolveComponent("ElDivider");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [
        state.current === 0 ? (_openBlock(), _createBlock(_component_el_form, {
          key: 0,
          size: "large",
          model: formData.value,
          ref_key: "loginForm",
          ref: loginForm,
          rules: loginRules.value
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, _toDisplayString(_unref(t)("\u767B\u5F55")), 1),
            _createVNode(_component_el_form_item, { prop: "account" }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  placeholder: usernamePlaceholder.value,
                  modelValue: formData.value.account,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => formData.value.account = $event),
                  modelModifiers: { trim: true }
                }, {
                  prefix: _withCtx(() => [
                    _createVNode(_component_svg_icon, { "icon-class": "surname" })
                  ]),
                  _: 1
                }, 8, ["placeholder", "modelValue"])
              ]),
              _: 1
            }),
            state.loginType === "phone" ? (_openBlock(), _createBlock(_component_el_form_item, { key: 0 }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_3, [
                  _createVNode(_component_el_input, {
                    class: "flex1 mr-4",
                    modelValue: state.code,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event) => state.code = $event),
                    placeholder: _unref(t)("\u8BF7\u8F93\u5165\u9A8C\u8BC1\u7801")
                  }, null, 8, ["modelValue", "placeholder"]),
                  !state.isSend ? (_openBlock(), _createBlock(_component_ElButton, {
                    key: 0,
                    onClick: getCode
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_unref(t)("\u83B7\u53D6\u9A8C\u8BC1\u7801")), 1)
                    ]),
                    _: 1
                  })) : (_openBlock(), _createBlock(_component_ElButton, {
                    key: 1,
                    disabled: state.countdown > 0,
                    onClick: getCode
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_unref(t)("\u91CD\u65B0\u53D1\u9001")) + " ", 1),
                      state.countdown > 0 ? (_openBlock(), _createElementBlock("span", _hoisted_4, "(" + _toDisplayString(state.countdown) + ")", 1)) : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }, 8, ["disabled"]))
                ])
              ]),
              _: 1
            })) : (_openBlock(), _createBlock(_component_el_form_item, {
              key: 1,
              prop: "password"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  placeholder: _unref(t)("\u5BC6\u7801"),
                  type: "password",
                  onKeydown: _withKeys(login, ["enter"]),
                  modelValue: formData.value.password,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event) => formData.value.password = $event),
                  modelModifiers: { trim: true }
                }, {
                  prefix: _withCtx(() => [
                    _createVNode(_component_svg_icon, { "icon-class": "password" })
                  ]),
                  _: 1
                }, 8, ["placeholder", "onKeydown", "modelValue"])
              ]),
              _: 1
            })),
            _unref(nvcLoginState).renderedNc || _unref(nvcSendVerifyCodeState).renderedNc ? (_openBlock(), _createBlock(_component_el_form_item, {
              key: 2,
              style: { "height": "40px" },
              prop: "afs"
            }, {
              default: _withCtx(() => [
                _hoisted_5
              ]),
              _: 1
            })) : _createCommentVNode("", true),
            _createElementVNode("div", null, [
              _createVNode(_component_el_checkbox, {
                size: "default",
                modelValue: formData.value.remember,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event) => formData.value.remember = $event)
              }, {
                default: _withCtx(() => [
                  _createElementVNode("span", _hoisted_6, _toDisplayString(_unref(t)("\u8BB0\u4F4F\u5F53\u524D\u8D26\u53F7")), 1)
                ]),
                _: 1
              }, 8, ["modelValue"])
            ]),
            _createElementVNode("div", _hoisted_7, [
              _createVNode(_component_el_button, {
                type: "primary",
                size: "large",
                class: "button-big",
                style: { "width": "100%" },
                onClick: login,
                loading: state.submitLoading
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_unref(t)("\u767B\u5F55")), 1)
                ]),
                _: 1
              }, 8, ["loading"])
            ]),
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("span", {
                class: "float-left pt-4 text-sm text-gray-500 cursor-pointer",
                onClick: _cache[4] || (_cache[4] = ($event) => _ctx.$router.push("/reset_pwd?account=" + formData.value.account))
              }, _toDisplayString(_unref(t)("\u5FD8\u8BB0\u5BC6\u7801")), 1),
              _createElementVNode("span", {
                class: "float-right pt-4 text-sm text-gray-500 cursor-pointer",
                onClick: _cache[5] || (_cache[5] = ($event) => toggleAccountType())
              }, _toDisplayString(isMainAccountLoginMode.value ? _unref(t)("\u5B50\u8D26\u53F7\u767B\u9646") : _unref(t)("\u4E3B\u8D26\u53F7\u767B\u9646")), 1)
            ])
          ]),
          _: 1
        }, 8, ["model", "rules"])) : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_9, [
          _createVNode(_component_ElDivider, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_unref(t)("\u5176\u4ED6\u767B\u5F55\u65B9\u5F0F")), 1)
            ]),
            _: 1
          }),
          _createElementVNode("div", _hoisted_10, [
            isMainAccountLoginMode.value ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              state.loginType !== "phone" ? (_openBlock(), _createBlock(_component_ElButton, {
                key: 0,
                onClick: handleChangePhone
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_svg_icon, { "icon-class": "yzm_phone" }),
                  _createTextVNode(" " + _toDisplayString(_unref(t)("\u514D\u5BC6\u7801\u767B\u5F55")), 1)
                ]),
                _: 1
              })) : (_openBlock(), _createBlock(_component_ElButton, {
                key: 1,
                class: "h-[32px] round-[16px]",
                onClick: handleChangeEmail
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_svg_icon, { "icon-class": "yzm" }),
                  _createTextVNode(" " + _toDisplayString(_unref(t)("\u5BC6\u7801\u767B\u5F55")), 1)
                ]),
                _: 1
              }))
            ], 64)) : _createCommentVNode("", true),
            _createVNode(_component_svg_icon, {
              "icon-class": "icon-zhifubao",
              class: _normalizeClass(["text-[32px] rounded-full cursor-pointer", [isSubAccountLoginMode.value ? "" : "ml-4"]]),
              onClick: loginAlipay
            }, null, 8, ["class"]),
            _createVNode(_component_svg_icon, {
              "icon-class": "icon-weixin1",
              class: "ml-4 text-[32px] cursor-pointer",
              onClick: loginWechat
            })
          ]),
          isMainAccountLoginMode.value ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
            _createElementVNode("span", _hoisted_12, [
              _createTextVNode(_toDisplayString(_unref(t)("\u8FD8\u6CA1\u6709\u8D26\u53F7,\u53BB")) + " ", 1),
              _createElementVNode("span", {
                style: { "color": "#409EFF" },
                class: "cursor-pointer",
                onClick: _cache[6] || (_cache[6] = //@ts-ignore
                (...args) => _unref(goRegister) && _unref(goRegister)(...args))
              }, _toDisplayString(_unref(t)("\u6CE8\u518C")), 1)
            ])
          ])) : _createCommentVNode("", true)
        ]),
        state.showBind ? (_openBlock(), _createBlock(AuthBind, {
          key: 1,
          visible: state.showBind,
          "onUpdate:visible": _cache[7] || (_cache[7] = ($event) => state.showBind = $event),
          title: _unref(t)("\u7B2C\u4E09\u65B9\u5E73\u53F0\u767B\u5F55")
        }, null, 8, ["visible", "title"])) : _createCommentVNode("", true)
      ]);
    };
  }
});
