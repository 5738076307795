import { post } from "@/utils/request";
import { RegisterForm, LoginForm } from "@/types/auth";
import {
  VerifyMethodParams,
  VerifyMethodInfoParams,
  VerifyMoifyParams,
} from "@/types/auth";

// 注册
export function register(data: RegisterForm) {
  return post("/auth/register", data);
}

// 登录
export function login(data: LoginForm) {
  return post("/auth/login", data);
}

// 获取验证方法列表
export function verifyMethodList(data: { verifyId: string  }) {
  return post("/auth/verify/methods/list", data);
}

// 获取验证详情
export function verifyMethodInfo(data: VerifyMethodInfoParams) {
  return post("/auth/verify/methods/info", data);
}

// 发送验证码
export function verifySend(data: VerifyMethodInfoParams) {
  return post("/auth/verify/send", data);
}
// 发送验证码
export function verifyCodeSend(data: VerifyMoifyParams) {
  return post("/auth/verify/code/send", data);
}
// /user/verify/phone/send
// 激活手机号
export function verifyPhoneSend(data: unknown) {
  return post("/user/verify/phone/send", data);
}
// 验证码登录
export function loginVerify(data: unknown) {
  return post("/auth/login/verify/code", data);
}

// 验证
export function verify(data: VerifyMethodParams & { verifyCode: string }) {
  return post("/auth/verify/verify", data);
}

// 重新发送验证邮件
export function resend(data: { verifyId: string }) {
  return post("/auth/active/resend", data);
}

// 激活
export function activeFinish(data: { password: string; verifyId: string }) {
  return post("/auth/active/finish", data);
}

// 获取注册信息
export function registerInfo(verifyId: string) {
  return post("/auth/active/register/info/get", {
    verifyId,
  });
}

//发送忘记密码验证码
export function resetPasswordSend(data: any) {
  return post("/auth/reset/password/send", data);
}

// 获取当前忘记密码的账户信息
export function getResetPasswordInfo(verifyId: string) {
  return post("/auth/reset/password/get", {
    verifyId,
  });
}

// 重设密码
export function resetPassword(data: { verifyId: string; password: string }) {
  return post("/auth/reset/password", data);
}

// 获取登录邮箱的url
export function loginUrl(email: string) {
  return post("/auth/email/login/url", {
    email,
  });
}

// 第三方平台登录
export function loginByThird(type: string, authCode: string) {
  return post("/auth/login/third", {
    authCode,
    type,
  });
}

// 验证码登录
export function loginByCode(type: string, authCode: string) {
  return post("/auth/login/verify/code", {
    authCode,
    type,
  });
}

// 登出
export function logout() {
  return post("/logout");
}

// 获取环境列表
export function getEnvList(account: string) {
  return post("/auth/env/list", { account });
}
// 登录应用
export function loginApp(data: unknown) {
  return post("/auth/login/app", data);
}

// 获取rsa加密key
export function getLoginKey() {
  return post("/auth/login/key");
}
