import { defineComponent as _defineComponent } from "vue";
import { unref as _unref, resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withModifiers as _withModifiers, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = (n) => (_pushScopeId("data-v-29ddc33b"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "store-app-item" };
const _hoisted_2 = { class: "flex flex-col h-full relative cursor-pointer" };
const _hoisted_3 = { class: "flex w-full" };
const _hoisted_4 = ["src"];
const _hoisted_5 = { class: "flex-1 pl-4 pt-2 w-0" };
const _hoisted_6 = { class: "flex items-center relative" };
const _hoisted_7 = { class: "app-name font-medium text-hide" };
const _hoisted_8 = {
  key: 0,
  class: "app-version"
};
const _hoisted_9 = { class: "app-item-footer" };
const _hoisted_10 = { class: "app-desc text-xs pt-2 text-gray-500" };
const _hoisted_11 = { class: "actions" };
import { useI18nTransform } from "@/hooks/useI18nTransform";
export default /* @__PURE__ */ _defineComponent({
  __name: "item",
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  emits: ["tryout", "buy"],
  setup(__props, { emit }) {
    const props = __props;
    const imgUrl = require("@/assets/images/product_default.png");
    const { t } = useI18nTransform();
    function handleTryout() {
      emit("tryout", props.data);
    }
    function handleBuy() {
      emit("buy", props.data);
    }
    return (_ctx, _cache) => {
      const _component_CButton = _resolveComponent("CButton");
      const _directive_lazy = _resolveDirective("lazy");
      const _directive_tooltip = _resolveDirective("tooltip");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _withDirectives(_createElementVNode("img", {
              src: __props.data.logo,
              alt: "",
              class: "app-icon mt-2"
            }, null, 8, _hoisted_4), [
              [_directive_lazy, {
                src: __props.data.logo,
                error: _unref(imgUrl),
                loading: _unref(imgUrl)
              }]
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _withDirectives((_openBlock(), _createElementBlock("span", _hoisted_7, [
                  _createTextVNode(_toDisplayString(__props.data.name), 1)
                ])), [
                  [_directive_tooltip]
                ])
              ]),
              __props.data.productVersion ? (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString(__props.data.productVersion), 1)) : _createCommentVNode("", true)
            ])
          ]),
          _createElementVNode("div", _hoisted_9, [
            _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_10, [
              _createTextVNode(_toDisplayString(__props.data.description), 1)
            ])), [
              [_directive_tooltip]
            ]),
            _createElementVNode("div", _hoisted_11, [
              __props.data.tryout ? (_openBlock(), _createBlock(_component_CButton, {
                key: 0,
                text: "",
                disabled: !__props.data.tryout,
                class: _normalizeClass([{ "is-disabled": !__props.data.tryout }, "text-sm py-1"]),
                onClick: _withModifiers(handleTryout, ["stop"])
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_unref(t)("\u8BD5\u7528")), 1)
                ]),
                _: 1
              }, 8, ["disabled", "class", "onClick"])) : _createCommentVNode("", true),
              _createVNode(_component_CButton, {
                text: "",
                class: "text-sm py-1",
                onClick: _withModifiers(handleBuy, ["stop"])
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_unref(t)("\u8D2D\u4E70")), 1)
                ]),
                _: 1
              }, 8, ["onClick"])
            ])
          ])
        ])
      ]);
    };
  }
});
