export enum ORDER_STATUS {
  WAIT_PUYER_PAY = 'wait_buyer_pay',
  TRADE_SUCCESS = 'trade_success',
  TRADE_FAIL = 'trade_fail',
  TRADE_CLOSED = 'trade_closed',
  TRADE_REFUND_SUCCESS = 'trade_refund_success',
  TRADE_REFUND_FAIL = 'trade_refund_fail',
  TRADE_REFUND_APPLY = 'trade_refund_apply',
  TRADE_REFUND_APPLY_REFUSE = 'trade_refund_apply_refuse',
  TRADE_PAY_CONFIRMED = 'trade_pay_confirmed',
  TRADE_PAY_CONFIRMED_FAIL = "trade_pay_confirmed_fail",
}

export enum INSTALL_STATUS {
  INSTALL_SUCCESS = 'install_success',
  INSTALL_FAIL = 'install_fail',
  INVALID = 'invalid',
}

export enum RESOURCE_STATUS {
  INSTALL_SUCCESS = 'install_success',
  INSTALLING = 'installing',
  INSTALL_FAIL = 'install_fail',
  INVALID = 'invalid',
}

export enum VALUE_ADDED_APPROVAL_STATE {
  PASS = "pass",
  REJECT = "reject",
  PROCESS = "process",
}
