import { getMetaLangList, updateLang, getLangTitle } from '@/api/lang'
import { LangInfo } from '@/types/user'
import {useAuthStore} from '@/store/modules/auth'
import { ElMessage } from 'element-plus'
import { ref, computed } from 'vue'
import { useI18nTransform } from '@/hooks/useI18nTransform'
import { loadLanguage, setLanguageCode, getLanguageCode } from '@/i18n'

export function useLang() {
  const authStore = useAuthStore()
  const { t } = useI18nTransform()
  const currentLang = ref(authStore.user?.lang || getLanguageCode() || 'zh-cn')
  const currentLangName = computed(() => {
    const l = langList.value.find(i => i.code === currentLang.value);
    if (l) return l.name;
    return '简体中文'
  });
  const langList = ref<LangInfo[]>([]);
  // 可选语言列表
  const langOptions = computed(() => langList.value.filter(i => i.code !== currentLang.value))
  // 获取语言列表
  getMetaLangList({
    offset: 0,
    pageSize: 1000,
  }).then((res: any) => {
    if (res.objects) {
      langList.value = res.objects;
    } else if (Array.isArray(res)) {
      langList.value = res;
    }
  });
  // 修改本地语言
  const changeLang = (lang: string) => {
    loadLanguage(lang).then(() => {
      ElMessage.success(t('切换语言成功'));
      currentLang.value = lang;
      setLanguageCode(lang);
    }).catch(() => {
      ElMessage.error(t('切换语言失败'));
    })
  };
  const updateUserLang = (lang: string) => {
    updateLang({
      id: authStore.user?.tenantId,
      lang: lang,
      // timeZone: authStore.user?.timeZone,
    })
      .then((res) => {
        if (res) {
          ElMessage.success(t('切换语言成功, 即将重新启动'));
          setTimeout(() => {
            location.reload();
          }, 1000);
        }
      })
      .catch(() => {
        ElMessage.error(t('切换语言失败'));
      });
  };
 
  return {
    changeLang,
    updateUserLang,
    currentLang,
    currentLangName,
    langList,
    langOptions,
  }
}