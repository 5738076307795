

export const CompanySizes = [
    { name : '10人以下' },
    { name : '10人-50人' },
    { name : '50人-100人' },
    { name : '100人-500人' },
    { name : '500人-1000人' },
    { name : '1000人以上' },
]
