import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
import _imports_0 from "@/assets/images/banner.png";
const _withScopeId = (n) => (_pushScopeId("data-v-2535e021"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "home" };
const _hoisted_2 = { class: "banner bg-cover bg-no-repeat bg-center" };
const _hoisted_3 = { class: "banner-coninater flex justify-between items-center m-auto" };
const _hoisted_4 = { class: "banner-action" };
const _hoisted_5 = {
  class: "text-5xl font-bold animate__animated animate__fadeInDown",
  style: { "color": "#333333" }
};
const _hoisted_6 = /* @__PURE__ */ _withScopeId(() => /* @__PURE__ */ _createElementVNode("div", { class: "m-4" }, null, -1));
const _hoisted_7 = { class: "pt-6 banner-desc text-xl animate__animated animate__fadeInDown" };
const _hoisted_8 = /* @__PURE__ */ _withScopeId(() => /* @__PURE__ */ _createElementVNode("span", { class: "px-2" }, "(LCDP)", -1));
const _hoisted_9 = /* @__PURE__ */ _withScopeId(() => /* @__PURE__ */ _createElementVNode("br", null, null, -1));
const _hoisted_10 = { class: "action pt-12" };
const _hoisted_11 = {
  key: 0,
  class: "animate__animated animate__fadeInDown"
};
const _hoisted_12 = /* @__PURE__ */ _withScopeId(() => /* @__PURE__ */ _createElementVNode("img", {
  class: "banner-img animate__animated animate__fadeInRight",
  src: _imports_0,
  alt: ""
}, null, -1));
const _hoisted_13 = { class: "home-coninater" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_AppHeaderDefault = _resolveComponent("AppHeaderDefault");
  const _component_Indroduce = _resolveComponent("Indroduce");
  const _component_ProductIndroduce = _resolveComponent("ProductIndroduce");
  const _component_UserCase = _resolveComponent("UserCase");
  const _component_AppFooter = _resolveComponent("AppFooter");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_AppHeaderDefault, { class: "container" }),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("h1", _hoisted_5, [
            _createTextVNode(_toDisplayString(_ctx.t("\u5FEB\u901F\u6784\u5EFA")), 1),
            _hoisted_6,
            _createTextVNode(" " + _toDisplayString(_ctx.t("\u4F01\u4E1A\u5E94\u7528")), 1)
          ]),
          _createElementVNode("h2", _hoisted_7, [
            _createTextVNode(_toDisplayString(_ctx.t("\u901A\u8FC7\u4F4E\u4EE3\u7801\u5F00\u53D1\u5E73\u53F0")) + " ", 1),
            _hoisted_8,
            _createTextVNode(" " + _toDisplayString(_ctx.t("\u5FEB\u901F")), 1),
            _hoisted_9,
            _createTextVNode(_toDisplayString(_ctx.t("\u6784\u5EFA\u4EA7\u54C1")), 1)
          ]),
          _createElementVNode("div", _hoisted_10, [
            !_ctx.authStore.hasUser ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
              _createElementVNode("button", {
                class: "button-app",
                onClick: _cache[0] || (_cache[0] = ($event) => _ctx.$router.push({ path: "/app-login" }))
              }, _toDisplayString(_ctx.t("\u5E94\u7528\u767B\u5F55")), 1),
              _createElementVNode("button", {
                class: "button-tenant",
                onClick: _cache[1] || (_cache[1] = ($event) => _ctx.$router.push({ path: "/login" }))
              }, _toDisplayString(_ctx.t("\u79DF\u6237\u767B\u5F55")), 1)
            ])) : _createCommentVNode("", true)
          ])
        ]),
        _hoisted_12
      ])
    ]),
    _createElementVNode("div", _hoisted_13, [
      _createVNode(_component_Indroduce),
      _createVNode(_component_ProductIndroduce),
      _createVNode(_component_UserCase),
      _createVNode(_component_AppFooter)
    ])
  ]);
}
