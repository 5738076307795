import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
// @ts-ignore
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import Home from "../views/Home/index.vue";
import Register from "../views/Auth/register.vue";
import Login from "../views/Auth/login.vue";
import Verify from "../views/Auth/verify.vue";

import { getUrlQuery } from "@/utils/helper";
import { sendAuthData } from "@/utils/auth";
import { pay, payFinish } from "@/hooks/usePay";
import { useAuthStore } from "@/store/modules/auth";
import { useAppStore } from "@/store/modules/app";
import { loadLanguage, getLanguageCode } from "@/i18n";
// NProgress.configure({ minimum: 0.1 });

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/",
    name: "Layout",
    component: () => import("@/views/Layout/index.vue"),
    children: [
      {
        path: "/environment",
        name: "Environment",
        component: () => import("@/views/Environment/index.vue"),
      },
      {
        path: "/value-added",
        name: "valueAdded",
        component: () => import("@/views/ValueAdded/index.vue"),
      },
      {
        path: "/apps",
        name: "App",
        component: () => import("@/views/App/index.vue"),
        meta: { level: 1 },
      },
      {
        path: "/guide",
        name: "Guide",
        component: () => import("@/views/Guide/index.vue"),
      },
      {
        path: "/guide/notice",
        name: "GuideNotice",
        component: () => import("@/views/Guide/Notice/index.vue"),
      },
      {
        path: "/product-detail",
        name: "ProductDetail",
        component: () => import("@/views/Guide/Product/index.vue"),
        meta: { level: 2},
      },
    ],
  },
  {
    path: "/register",
    name: "Register",
    component: Register,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/app-login",
    name: "AppLogin",
    component: () => import("@/views/Auth/AppLogin.vue"),
  },
  {
    path: "/verify",
    name: "Verify",
    component: Verify,
  },
  {
    path: "/account",
    name: "Account",
    component: () => import("@/views/Account/index.vue"),
    children: [
      {
        path: "user",
        name: "AccountUser",
        component: () => import("@/views/Account/User/index.vue"),
      },
      {
        path: "manager",
        name: "AccountManager",
        component: () => import("@/views/Account/Manager/index.vue"),
      },
      {
        path: "sub-account",
        name: "SubAccountManager",
        component: () => import("@/views/Account/SubAccount/index.vue"),
      },
    ],
  },
  {
    path: "/setting",
    name: "Setting",
    component: () => import("@/views/Setting/index.vue"),
    children: [
      {
        path: "company",
        name: "Company",
        component: () => import("@/views/Setting/Company/index.vue"),
      },
      {
        path: "company-auth",
        name: "CompanyAuth",
        component: () => import("@/views/Setting/Company/Auth.vue"),
      },
      {
        path: "company-info",
        name: "CompanyInfo",
        component: () => import("@/views/Setting/Company/Info.vue"),
      },
      {
        path: "account",
        name: "SettingAccount",
        component: () => import("@/views/Setting/Account/index.vue"),
      },
      {
        path: "account-info",
        name: "SettingAccountInfo",
        component: () => import("@/views/Setting/Account/Info.vue"),
      },
      {
        path: "account-auth",
        name: "SettingAccountAuth",
        component: () => import("@/views/Setting/Account/Auth.vue"),
      },
      {
        path: "developer",
        name: "SettingDeveloper",
        component: () => import("@/views/Setting/Developer/index.vue"),
      },
      {
        path: "developer-auth",
        name: "SettingDeveloperAuth",
        component: () => import("@/views/Setting/Developer/Auth.vue"),
      },
      {
        path: "developer-info",
        name: "SettingDeveloperInfo",
        component: () => import("@/views/Setting/Developer/Info.vue"),
      },
    ],
  },
  {
    path: "/order",
    name: "OrderManager",
    component: () => import("@/views/Manager/Order/index.vue"),
    children: [
      {
        path: "list",
        name: "OrderList",
        // meta: { level: 1 },
        component: () => import("@/views/Manager/Order/List.vue"),
      },
      {
        path: "resource",
        name: "OrderResource",
        // meta: { level: 1 },
        component: () => import("@/views/Manager/Order/List.vue"),
      },
      {
        path: "list-detail",
        component: () => import("@/views/Manager/Order/Detail.vue"),
      },
      {
        path: "resource-detail",
        component: () => import("@/views/Manager/Order/DetailEnv.vue"),
      },
      {
        path: "value-added",
        name: "ValueAddredOrderList",
        // meta: { level: 1 },
        component: () => import("@/views/Manager/Order/ValueAdded/index.vue"),
      },
      {
        path: "value-added-detail",
        name: "ValueAddredOrderDetail",
        // meta: { level: 2 },
        component: () => import("@/views/Manager/Order/ValueAdded/Detail.vue"),
      },
    ],
  },
  {
    path: "/product",
    name: "ProductManager",
    component: () => import("@/views/Manager/Product/index.vue"),
    children: [
      {
        path: "release",
        name: "ProductRelease",
        meta: { level: 1 },
        component: () => import("@/views/Manager/Product/Release.vue"),
      },
      {
        path: "purchase",
        name: "ProductPurchase",
        meta: { level: 1 },
        component: () => import("@/views/Manager/Product/Purchase.vue"),
      },
    ],
  },
  {
    path: "/log",
    name: "Log",
    component: () => import("@/views/Log/index.vue"),
    children: [
      {
        path: "login",
        name: "LogLogin",
        component: () => import("@/views/Log/Login/index.vue"),
      },

      {
        path: "operate",
        name: "LogOperate",
        component: () => import("@/views/Log/Operate/index.vue"),
      },
    ],
  },
  {
    path: "/approval",
    name: "Approval",
    component: () => import("@/views/Approval/index.vue"),
    children: [
      {
        path: "value-added",
        name: "ApprovalValueAdded",
        component: () => import("@/views/Approval/ValueAdded/index.vue"),
      },
    ],
  },
  {
    path: "/pay",
    name: "Pay",
    component: () => import("@/views/App/pay.vue"),
  },
  {
    path: "/account_active",
    name: "AccountActive",
    component: () => import("@/views/Auth/accountActive.vue"),
  },
  {
    path: "/change_pwd",
    name: "ResetPasswordSubmit",
    component: () => import("@/views/Auth/accountActive.vue"),
  },
  {
    path: "/reset_pwd",
    name: "ResetPassword",
    component: () => import("@/views/Auth/resetPwd.vue"),
  },
];
const router = createRouter({
  history: createWebHistory('/console'),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // 始终滚动到顶部
    if (from.meta.level) {
      if ((from.meta.level as number) > ((to.meta.level || 0) as number))
      return  {
        top: savedPosition?.top || 0
      }
    }
    return { top: 0 };
  },
});

export const whiteList = [
  "/",
  "/register",
  "/login",
  "/app-login",
  "/auth",
  "/reset_pwd",
  "/change_pwd",
  "/verify",
  "/account_active",
];

function setupChatBox() {
  const box = document.getElementById("CYBERCLOUD_CHAT_BOX");
  if (!box) {
    const script = document.createElement("script");
    script.src =
      "https://cdn.loghub.com/assets/cybercloud-bot/latest/index.js?key=971019a31b5c48d0b18e8ad54eab5832";
    script.id = "CYBERCLOUD_CHAT_BOX";
    document.body.appendChild(script);
  }
}

function destroyChatBox() {
  const box = document.getElementById("CYBERCLOUD_CHAT_BOX");
  if (box) {
    box.remove();
  }
}

router.beforeEach(async (to, from, next) => {
  NProgress.start();
  if (/^\/auth/.test(to.path)) {
    sendAuthData(to.fullPath);
  } else if (/^\/product-pay/.test(to.path)) {
    pay();
  } else if (/^\/product\/pay/.test(to.path)) {
    payFinish();
  }
  const authStore = useAuthStore();
  try {
    if (!authStore.user && !whiteList.includes(location.pathname.replace('/console', ''))) {
      await authStore.getUserInfo();
      await loadLanguage(authStore.user!.lang || getLanguageCode());
    }
  } catch {
    try {
      await loadLanguage(authStore.user?.lang || getLanguageCode());
    } catch {
      console.log("loadLanguage");
    }
  }
 
  if (!authStore.user) {
    console.log("authStore  next");
    if (!whiteList.find((i) => i.indexOf(to.path) === 0)) {
      destroyChatBox();
      next("/");
      return;
    } else {
      destroyChatBox();
    }
  } else {
    setupChatBox();
  }
  const appStore = useAppStore();
  if (to.meta?.level === 1) {
    if (to.matched.length > 1) {
      appStore.addCacheRoute(to.matched[0].name as string);
    } else {
      appStore.addCacheRoute(to.name as string);
    }
  }
  if (from.meta?.level === 1) {
    if (to.meta?.level !== 2) {
      appStore.removeCacheRoute(from.name as string);
    }
  }
  if (from.name === 'App' && to.name !== 'OrderDetail') {
    appStore.removeCacheRoute('App')
  }
  // if (from.path === '/apps') {
  //   if (to.name !== 'ProductDetail') {
  //     appStore.removeCacheRoute(from.name as string)
  //   }
  // }
  console.log("next");
  next();
});

router.afterEach(() => {
  NProgress.done();
});

export default router;
