import {useRouter} from 'vue-router'


export function useRouterHelpers() {
    const router = useRouter()

    function goLogin(replace = false) {
        if (replace === true) router.replace({path: '/login'})
        else router.push({path: '/login'})
    }

    function goRegister() {
        router.push({path: '/register'})
    }

    function goHome() {
        router.push({path: '/'})
    }

    function goApps() {
        router.push({path: '/apps'})
    }
    function goEnv() {
        router.push({path: '/environment'}) 
    }
    function goEmailLogin(email ?: string) {
        if (email) {
            window.open(email)
        } else goLogin(true)
    }

    return {goLogin, goRegister, goHome, goApps, goEnv, goEmailLogin}
}
