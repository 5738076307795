import { defineComponent as _defineComponent } from "vue";
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = (n) => (_pushScopeId("data-v-ab37a24a"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "m-auto text-center" };
const _hoisted_2 = { class: "title animate__animated font-semibold text-2xl" };
const _hoisted_3 = /* @__PURE__ */ _withScopeId(() => /* @__PURE__ */ _createElementVNode("div", { class: "sub-title animate__animated font-semibold text-sm text-gray-300 pt-2" }, " Indroduce ", -1));
const _hoisted_4 = { class: "section-content flex justify-center xl:justify-between gap-x-20 gap-y-10 flex-wrap" };
const _hoisted_5 = { class: "name pt-4" };
const _hoisted_6 = { class: "description text-xs pt-3" };
const _hoisted_7 = { class: "name pt-4" };
const _hoisted_8 = { class: "description text-xs pt-3" };
const _hoisted_9 = { class: "name pt-4" };
const _hoisted_10 = { class: "description text-xs pt-3" };
const _hoisted_11 = { class: "name pt-4" };
const _hoisted_12 = { class: "description text-xs pt-3" };
import { ref, onMounted } from "vue";
import { useI18nTransform } from "@/hooks/useI18nTransform";
export default /* @__PURE__ */ _defineComponent({
  __name: "Indroduce",
  setup(__props) {
    const { t } = useI18nTransform();
    const current = ref(-1);
    const indroduceRef = ref();
    function handleMouseIn(index) {
      current.value = index;
    }
    function handleMouseOut() {
      current.value = -1;
    }
    onMounted(() => {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((item) => {
            var _a;
            if (item.isIntersecting) {
              const eles = (_a = indroduceRef.value) == null ? void 0 : _a.querySelectorAll(".animate__animated");
              if (eles) {
                eles.forEach((ele, index) => {
                  setTimeout(() => {
                    ele.classList.add("fadeInDownMi");
                  }, 200 * index);
                });
              }
              observer.unobserve(indroduceRef.value);
              observer.disconnect();
            }
          });
        },
        {
          threshold: [0.4]
        }
      );
      observer.observe(indroduceRef.value);
    });
    return (_ctx, _cache) => {
      const _component_svg_icon = _resolveComponent("svg-icon");
      return _openBlock(), _createElementBlock("section", {
        class: "indroduce",
        ref_key: "indroduceRef",
        ref: indroduceRef
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, _toDisplayString(_unref(t)("\u5E73\u53F0\u4ECB\u7ECD")), 1),
          _hoisted_3,
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", {
              class: "indroduce-item animate__animated flex flex-col items-center",
              onMouseover: _cache[0] || (_cache[0] = ($event) => handleMouseIn(0)),
              onMouseleave: handleMouseOut
            }, [
              _createVNode(_component_svg_icon, {
                "icon-class": "icon-yingyong",
                "class-name": "indroduce-icon"
              }),
              _createElementVNode("div", _hoisted_5, _toDisplayString(_unref(t)("\u5E94\u7528")), 1),
              _createElementVNode("div", _hoisted_6, _toDisplayString(_unref(t)("\u53EF\u89C6\u5316\u5F00\u53D1\u5DE5\u5177\uFF0C\u4E1A\u52A1\u548C\u5F00\u53D1\u4EBA\u5458\u534F\u540C\u5F00\u53D1\uFF0C\u5FEB\u901F\u9AD8\u6548\u7684\u6EE1\u8DB3\u4E1A\u52A1\u9700\u6C42")), 1)
            ], 32),
            _createElementVNode("div", {
              class: "indroduce-item animate__animated flex flex-col items-center",
              onMouseover: _cache[1] || (_cache[1] = ($event) => handleMouseIn(1)),
              onMouseleave: handleMouseOut
            }, [
              _createVNode(_component_svg_icon, {
                "icon-class": "icon-yun",
                "class-name": "indroduce-icon"
              }),
              _createElementVNode("div", _hoisted_7, _toDisplayString(_unref(t)("\u4E91")), 1),
              _createElementVNode("div", _hoisted_8, _toDisplayString(_unref(t)("\u65E0\u9700\u4E13\u4E1A\u6280\u80FD\uFF0C\u4E0D\u5173\u5FC3\u57FA\u7840\u8BBE\u65BD\uFF0C\u5FEB\u901F\u6784\u5EFA\u548C\u4EA4\u4ED8\u5E94\u7528\uFF0C\u5F39\u6027\u4F38\u7F29\u3001\u4E00\u952E\u90E8\u7F72")), 1)
            ], 32),
            _createElementVNode("div", {
              class: "indroduce-item animate__animated flex flex-col items-center",
              onMouseover: _cache[2] || (_cache[2] = ($event) => handleMouseIn(2)),
              onMouseleave: handleMouseOut
            }, [
              _createVNode(_component_svg_icon, {
                "icon-class": "icon-anquanhegui",
                "class-name": "indroduce-icon"
              }),
              _createElementVNode("div", _hoisted_9, _toDisplayString(_unref(t)("\u5B89\u5168\u5408\u89C4")), 1),
              _createElementVNode("div", _hoisted_10, _toDisplayString(_unref(t)("\u4FDD\u969C\u5E94\u7528\u7A0B\u5E8F\u5B89\u5168\u3001\u6570\u636E\u5B89\u5168")), 1)
            ], 32),
            _createElementVNode("div", {
              class: "indroduce-item animate__animated flex flex-col items-center",
              onMouseover: _cache[3] || (_cache[3] = ($event) => handleMouseIn(3)),
              onMouseleave: handleMouseOut
            }, [
              _createVNode(_component_svg_icon, {
                "icon-class": "icon-xitongjicheng",
                "class-name": "indroduce-icon"
              }),
              _createElementVNode("div", _hoisted_11, _toDisplayString(_unref(t)("\u7CFB\u7EDF\u96C6\u6210")), 1),
              _createElementVNode("div", _hoisted_12, _toDisplayString(_unref(t)("\u63D0\u4F9B\u5F00\u653E\u6027\uFF0C\u94FE\u63A5\u591A\u65B9\u5E94\u7528\u548C\u6570\u636E")), 1)
            ], 32)
          ])
        ])
      ], 512);
    };
  }
});
