var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { defineComponent as _defineComponent } from "vue";
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue";
import { ref, onMounted, reactive, computed } from "vue";
import { required, email, phone } from "@/utils/validate";
import { regionList } from "@/api/region";
import { CompanySizes } from "@/constans/company";
import { useI18nTransform } from "@/hooks/useI18nTransform";
const gutter = 20;
export default /* @__PURE__ */ _defineComponent({
  __name: "register",
  setup(__props, { expose: __expose }) {
    const { t, toObjectArray } = useI18nTransform();
    const formData = ref({
      companyName: "",
      surname: "",
      name: "",
      email: "",
      phoneNo: "",
      companySize: "",
      state: "",
      message: ""
    });
    console.log("\u8BF7\u8F93\u5165");
    const rules = computed(() => ({
      companyName: [
        required(t("\u8BF7\u8F93\u5165"))
      ],
      surname: [
        required(t("\u8BF7\u8F93\u5165"))
      ],
      name: [
        required(t("\u8BF7\u8F93\u5165"))
      ],
      email: [
        required(t("\u8BF7\u8F93\u5165")),
        email()
      ],
      phoneNo: [
        required(t("\u8BF7\u8F93\u5165")),
        phone()
      ],
      companySize: [
        required(t("\u8BF7\u9009\u62E9"), ["change"])
      ],
      state: [
        required(t("\u8BF7\u8F93\u5165"))
      ]
    }));
    const form = ref(null);
    const state = reactive({
      regions: []
    });
    const getValue = () => {
      return new Promise((resolve, reject) => {
        if (!form.value)
          reject();
        form.value.validate((valid) => {
          if (!valid)
            reject();
          const fd = __spreadValues({}, formData.value);
          fd.name = fd.surname + fd.name;
          resolve(fd);
        });
      });
    };
    function fetchRegionList() {
      regionList().then((d) => {
        state.regions = d;
      });
    }
    onMounted(() => {
      fetchRegionList();
    });
    __expose({
      getValue
    });
    return (_ctx, _cache) => {
      const _component_svg_icon = _resolveComponent("svg-icon");
      const _component_el_input = _resolveComponent("el-input");
      const _component_el_form_item = _resolveComponent("el-form-item");
      const _component_el_col = _resolveComponent("el-col");
      const _component_el_row = _resolveComponent("el-row");
      const _component_el_option = _resolveComponent("el-option");
      const _component_el_select = _resolveComponent("el-select");
      const _component_el_form = _resolveComponent("el-form");
      return _openBlock(), _createBlock(_component_el_form, {
        size: "large",
        model: formData.value,
        ref_key: "form",
        ref: form,
        rules: rules.value
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_row, { gutter }, {
            default: _withCtx(() => [
              _createVNode(_component_el_col, { span: 12 }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_form_item, { prop: "surname" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_input, {
                        placeholder: _unref(t)("\u59D3"),
                        modelValue: formData.value.surname,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => formData.value.surname = $event),
                        modelModifiers: { trim: true }
                      }, {
                        prefix: _withCtx(() => [
                          _createVNode(_component_svg_icon, { "icon-class": "surname" })
                        ]),
                        _: 1
                      }, 8, ["placeholder", "modelValue"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_el_col, { span: 12 }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_form_item, { prop: "name" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_input, {
                        placeholder: _unref(t)("\u540D"),
                        modelValue: formData.value.name,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event) => formData.value.name = $event),
                        modelModifiers: { trim: true }
                      }, {
                        prefix: _withCtx(() => [
                          _createVNode(_component_svg_icon, { "icon-class": "name" })
                        ]),
                        _: 1
                      }, 8, ["placeholder", "modelValue"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_el_row, { gutter }, {
            default: _withCtx(() => [
              _createVNode(_component_el_col, { span: 12 }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_form_item, { prop: "email" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_input, {
                        placeholder: _unref(t)("\u7535\u5B50\u90AE\u7BB1"),
                        modelValue: formData.value.email,
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event) => formData.value.email = $event),
                        modelModifiers: { trim: true }
                      }, {
                        prefix: _withCtx(() => [
                          _createVNode(_component_svg_icon, { "icon-class": "email" })
                        ]),
                        _: 1
                      }, 8, ["placeholder", "modelValue"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_el_col, { span: 12 }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_form_item, { prop: "phoneNo" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_input, {
                        placeholder: _unref(t)("\u5DE5\u4F5C\u7535\u8BDD"),
                        maxlength: 11,
                        modelValue: formData.value.phoneNo,
                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event) => formData.value.phoneNo = $event),
                        modelModifiers: { trim: true }
                      }, {
                        prefix: _withCtx(() => [
                          _createVNode(_component_svg_icon, { "icon-class": "phone" })
                        ]),
                        _: 1
                      }, 8, ["placeholder", "modelValue"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_el_form_item, { prop: "companyName" }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                placeholder: _unref(t)("\u516C\u53F8\u540D\u79F0"),
                modelValue: formData.value.companyName,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event) => formData.value.companyName = $event),
                modelModifiers: { trim: true }
              }, {
                prefix: _withCtx(() => [
                  _createVNode(_component_svg_icon, { "icon-class": "company" })
                ]),
                _: 1
              }, 8, ["placeholder", "modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_row, { gutter }, {
            default: _withCtx(() => [
              _createVNode(_component_el_col, { span: 12 }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_form_item, { prop: "companySize" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_select, {
                        placeholder: _unref(t)("\u516C\u53F8\u89C4\u6A21"),
                        modelValue: formData.value.companySize,
                        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event) => formData.value.companySize = $event),
                        modelModifiers: { trim: true }
                      }, {
                        prefix: _withCtx(() => [
                          _createVNode(_component_svg_icon, {
                            "icon-class": "scale",
                            style: { "margin-top": "0" }
                          })
                        ]),
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(toObjectArray)(_unref(CompanySizes), "name"), (c) => {
                            return _openBlock(), _createBlock(_component_el_option, {
                              key: c.name,
                              label: c.name,
                              value: c.name
                            }, null, 8, ["label", "value"]);
                          }), 128))
                        ]),
                        _: 1
                      }, 8, ["placeholder", "modelValue"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_el_col, { span: 12 }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_form_item, { prop: "status" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_select, {
                        placeholder: _unref(t)("\u56FD\u5BB6/\u5730\u533A"),
                        style: { "width": "240px" },
                        modelValue: formData.value.state,
                        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event) => formData.value.state = $event)
                      }, {
                        prefix: _withCtx(() => [
                          _createVNode(_component_svg_icon, {
                            "icon-class": "country",
                            style: { "margin-top": "0" }
                          })
                        ]),
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(state.regions, (r) => {
                            return _openBlock(), _createBlock(_component_el_option, {
                              key: r.id,
                              label: r.name,
                              value: r.id
                            }, null, 8, ["label", "value"]);
                          }), 128))
                        ]),
                        _: 1
                      }, 8, ["placeholder", "modelValue"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_el_form_item, { prop: "message" }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                placeholder: _unref(t)("\u7559\u8A00"),
                modelValue: formData.value.message,
                "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event) => formData.value.message = $event),
                modelModifiers: { trim: true }
              }, {
                prefix: _withCtx(() => [
                  _createVNode(_component_svg_icon, { "icon-class": "leave-message" })
                ]),
                _: 1
              }, 8, ["placeholder", "modelValue"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["model", "rules"]);
    };
  }
});
