import type { I18n, VueI18n } from "vue-i18n";
import { getLangTitle } from "@/api/lang";

export function useModule(i18n: I18n) {
  // 已加载语言模块列表
  const langLoaded: any = {};

  const hasLang = (lang: string) => {
    return !!langLoaded[lang];
  };
  const has = (lang: string, module: string) => {
    return !!langLoaded[lang]?.[module];
  };
  const setLangConfig = (lang: string, module: string, data: any) => {
    if (!hasLang(lang)) {
      langLoaded[lang] = {};
    }
    if (!has(lang, module)) {
      langLoaded[lang][module] = {};
    }
    langLoaded[lang][module] = data;
  };
  const loadModule = (lang: string) => {
    // if (has(lang, module)) return null;
    return getLangTitle(lang).then((data: any) => {
      const message = i18n.global.getLocaleMessage(lang);
      if (hasLang(lang) || Object.keys(message).length > 0) {
        i18n.global.mergeLocaleMessage(lang, data);
      } else {
        i18n.global.setLocaleMessage(lang, data);
        langLoaded[lang] = data;
      }
      (i18n.global.locale as any).value = lang;
      // langLoaded[lang] = true;
    });
  };
  return {
    loadModule,
  };
}
