import { defineComponent as _defineComponent } from "vue";
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = (n) => (_pushScopeId("data-v-ef743bb6"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "m-auto text-center" };
const _hoisted_2 = { class: "title animate__animated font-semibold text-2xl" };
const _hoisted_3 = /* @__PURE__ */ _withScopeId(() => /* @__PURE__ */ _createElementVNode("div", { class: "sub-title animate__animated font-semibold text-sm text-gray-300 pt-2" }, " Product\xA0\xA0Indroduce ", -1));
const _hoisted_4 = { class: "section-content" };
const _hoisted_5 = { class: "relative animate__animated" };
const _hoisted_6 = ["onClick"];
const _hoisted_7 = { class: "indroduce-content" };
const _hoisted_8 = { class: "font-semibold text-2xl" };
const _hoisted_9 = { class: "text-gray-300 indroduce-desc" };
const _hoisted_10 = { class: "text-white rounded-full" };
const _hoisted_11 = { class: "product-indroduce-img" };
const _hoisted_12 = ["src"];
import { ref, onMounted } from "vue";
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import { Controller } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import { useI18nTransform } from "@/hooks/useI18nTransform";
export default /* @__PURE__ */ _defineComponent({
  __name: "ProductIndroduce",
  setup(__props) {
    const { t } = useI18nTransform();
    const products = [
      {
        name: "WMS",
        title: "WMS",
        subtitle: "\u4ED3\u50A8\u7BA1\u7406\u7CFB\u7EDF",
        description: "\u901A\u5929\u6653\u4EA7\u54C1\u5168\u9762\u652F\u6301B/S\u67B6\u6784\uFF0C\u7CFB\u7EDF\u652F\u6301\u4E91\u7AEF\u90E8\u7F72\uFF0C\u5E76\u652F\u6301\u591A\u5C42\u7EA7\u3001\u591A\u4ED3\u5E93\u3001\u591A\u8D27\u4E3B\u591A\u6A21\u5F0F\u3002\u771F\u6B63\u4E91\u7AEF\u90E8\u7F72\u67B6\u6784\u3001\u591A\u5C42\u7EA7\u7BA1\u7406\u4F53\u7CFB\u3001\u5168\u7403\u90E8\u7F72\u80FD\u529B\uFF1B\u7CBE\u7EC6\u5316\u5E93\u5B58\u7BA1\u7406\u3001\u7CBE\u76CA\u5316\u4F5C\u4E1A\u7BA1\u7406\u3001\u5168\u9762\u7EE9\u6548\u7BA1\u7406\u3002",
        image: require("@/assets/images/product_wms.png")
      },
      {
        name: "OFS",
        title: "OFS",
        subtitle: "\u8BA2\u5355\u5C65\u884C\u7CFB\u7EDF",
        description: "\u80FD\u6839\u636E\u4ED3\u5E93\u5E93\u5B58\u3001\u4ED3\u5E93\u8986\u76D6\u533A\u57DF\u3001\u8FD0\u8F93\u6210\u672C\u3001\u5C65\u7EA6\u65F6\u6548\u81EA\u52A8\u5206\u914D\u6700\u4F18\u53D1\u8D27\u4ED3\u5E93\uFF1B\u652F\u6301\u6309\u56DB\u7EA7\u5730\u5740\u533A\u57DF\u3001\u5E97\u94FA\u3001\u5546\u54C1\u3001\u5FEB\u9012\u6210\u672C\u7B49\u591A\u7EF4\u5EA6\u4F18\u9009\u5FEB\u9012\uFF1B\u7075\u6D3B\u53EF\u914D\u7F6E\u7684\u8BA2\u5355\u8425\u9500\u7B56\uFF0C\u8986\u76D6\u7535\u5546\u4FC3\u950095%\u573A\u666F\u3002",
        image: require("@/assets/images/product_ofs.png")
      },
      {
        name: "TMS",
        title: "TMS",
        subtitle: "\u8FD0\u8F93\u7BA1\u7406\u7CFB\u7EDF",
        description: "\u57FA\u4E8E\u8BA2\u5355\u6A21\u578B\u3001\u8BA2\u5355\u4EFB\u52A1\u7BA1\u7406\uFF0C\u57FA\u4E8E\u88C5\u8F7D\u4EFB\u52A1\u7684\u8F66\u8F86\u53F8\u673A\u7BA1\u7406\uFF0C\u914D\u9001\u8FC7\u7A0B\u5168\u7A0B\u5B9E\u65F6\u53EF\u89C6\u5316\u3001\u4EA4\u63A5\u9AD8\u6548\u4FBF\u6377\uFF0C\u5168\u9762\u517C\u5BB9\u5B89\u5353\u7CFB\u7EDF\u7684\u53F8\u673AAPP",
        image: require("@/assets/images/product_tms.png")
      },
      {
        name: "BMS",
        title: "BMS",
        subtitle: "\u8BA1\u8D39\u7BA1\u7406\u7CFB\u7EDF",
        description: "\u9AD8\u81EA\u5B9A\u4E49\u914D\u7F6E\u7684\u8BA1\u8D39\u7BA1\u7406\u7CFB\u7EDF\uFF0C\u57FA\u4E8E\u5408\u540C\u7684\u81EA\u52A8\u8BA1\u7B97\uFF0C\u57FA\u4E8E\u5408\u540C\u5BF9\u8C61\u548C\u4E1A\u52A1\u7C7B\u578B\u7684\u89C4\u5219\u5B9A\u4E49\uFF1B\u6807\u51C6\u5316\u5408\u540C\u8BBE\u7F6E\uFF0C\u57FA\u4E8E\u6807\u51C6\u8BED\u8A00\u7684\u8BA1\u8D39\u89C4\u5219\u8BBE\u7F6E\u4F53\u7CFB\uFF0C\u907F\u514D\u4EBA\u4E3A\u89E3\u8BFB\u9020\u6210\u504F\u5DEE\uFF1B\u652F\u6301\u6309\u7167\u5BA2\u6237\u6216\u8005\u533A\u57DF\u7B49\u591A\u89C6\u89D2\u7684\u6570\u636E\u5C55\u73B0\uFF1B\u8D39\u7528\u5BA1\u6838\u673A\u5236\u4FDD\u8BC1\u6570\u636E\u7684\u5B89\u5168\u6027\u548C\u51C6\u786E\u6027\u3002",
        image: require("@/assets/images/product_bms.png")
      },
      {
        name: "VA",
        title: "VA",
        subtitle: "\u53EF\u89C6\u5316\u548C\u5206\u6790",
        description: "\u57FA\u4E8E\u4E91\u8BA1\u7B97\u5E73\u53F0\u6784\u5EFA\u7684\u65B0\u4E00\u4EE3\u4F9B\u5E94\u94FE\u6570\u636E\u53EF\u89C6\u5316\u4EA7\u54C1\uFF0C\u5728\u4E91\u7AEF\u521B\u9020\u6027\u7684\u6253\u9020\u4E86\u4E00\u4E2A\u57FA\u4E8E\u6570\u636E\u6C47\u96C6\u3001\u5206\u6790\u3001\u5C55\u73B0\u4E3A\u4E00\u4F53\u7684\u6570\u636E\u89E3\u51B3\u65B9\u6848\u3002\u901A\u8FC7\u5BF9\u5E93\u5B58\u3001\u4ED3\u5185\u3001\u8FD0\u8F93\u4E1A\u52A1\u5173\u952E\u8282\u70B9\u7684\u6570\u636E\u76D1\u63A7\uFF0C\u5B9E\u65F6\u6C47\u96C6\u6570\u636E\u5230\u4E91\u7AEF\u6570\u636E\u4ED3\u5E93\uFF0C\u5E76\u6301\u7EED\u5206\u6790\uFF0C\u901A\u8FC7\u76F4\u89C2\u7684\u6570\u636E\u56FE\u8FDB\u884C\u5C55\u73B0\u3002",
        image: require("@/assets/images/product_va.png")
      }
    ];
    SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);
    const namesRef = ref();
    const left = ref(0);
    const swiperRef = ref();
    const controlledSwiper = ref(null);
    const indroduceRef = ref();
    const setControlledSwiper = (swiper) => {
      controlledSwiper.value = swiper;
    };
    function sliderMove(swiper) {
    }
    function slideChange(swiper) {
      const { activeIndex } = swiper;
      moveActive(activeIndex);
    }
    function moveActive(index) {
      var _a, _b;
      const child = (_b = (_a = namesRef.value) == null ? void 0 : _a.children) == null ? void 0 : _b[index];
      if (child) {
        left.value = child.offsetLeft + child.clientWidth / 2 - 15;
      }
    }
    function moveSwiper(index) {
      var _a;
      (_a = controlledSwiper.value) == null ? void 0 : _a.slideTo(index);
    }
    onMounted(() => {
      moveActive(0);
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((item) => {
            var _a;
            if (item.isIntersecting) {
              const eles = (_a = indroduceRef.value) == null ? void 0 : _a.querySelectorAll(".animate__animated");
              if (eles) {
                eles.forEach((ele, index) => {
                  setTimeout(() => {
                    ele.classList.add("fadeInDownMi");
                  }, 200 * index);
                });
              }
              observer.unobserve(indroduceRef.value);
              observer.disconnect();
            }
          });
        },
        {
          threshold: [0.4]
        }
      );
      observer.observe(indroduceRef.value);
    });
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("section", {
        class: "product-indroduce",
        ref_key: "indroduceRef",
        ref: indroduceRef
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, _toDisplayString(_unref(t)("\u4EA7\u54C1\u4ECB\u7ECD")), 1),
          _hoisted_3,
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", {
                class: "product-indroduce-names flex items-center justify-around",
                ref_key: "namesRef",
                ref: namesRef
              }, [
                (_openBlock(), _createElementBlock(_Fragment, null, _renderList(products, (item, index) => {
                  return _createElementVNode("div", {
                    class: "product-indroduce-name font-semibold text-xl",
                    key: item.name,
                    onClick: ($event) => moveSwiper(index)
                  }, _toDisplayString(item.name), 9, _hoisted_6);
                }), 64))
              ], 512),
              _createElementVNode("div", {
                class: "product-indroduce-line",
                style: _normalizeStyle({ left: left.value + "px" })
              }, null, 4)
            ]),
            _createVNode(_unref(Swiper), {
              class: "swiper-container animate__animated product-indroduce-list mt-8",
              ref_key: "swiperRef",
              ref: swiperRef,
              autoplay: false,
              loop: false,
              speed: 300,
              "space-between": 50,
              modules: [_unref(Controller)],
              onSwiper: setControlledSwiper,
              onSlideChange: slideChange
            }, {
              default: _withCtx(() => [
                (_openBlock(), _createElementBlock(_Fragment, null, _renderList(products, (item) => {
                  return _createVNode(_unref(SwiperSlide), {
                    class: "swiper-slide product-indroduce-item",
                    key: item.name
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_7, [
                        _createElementVNode("div", _hoisted_8, _toDisplayString(item.title) + "\xA0\xA0" + _toDisplayString(_unref(t)(item.subtitle)), 1),
                        _createElementVNode("div", _hoisted_9, _toDisplayString(_unref(t)(item.description)), 1),
                        _createElementVNode("button", _hoisted_10, _toDisplayString(_unref(t)("\u67E5\u770B\u8BE6\u60C5")), 1)
                      ]),
                      _createElementVNode("div", _hoisted_11, [
                        _createElementVNode("img", {
                          src: item.image,
                          alt: ""
                        }, null, 8, _hoisted_12)
                      ])
                    ]),
                    _: 2
                  }, 1024);
                }), 64))
              ]),
              _: 1
            }, 8, ["modules"])
          ])
        ])
      ], 512);
    };
  }
});
