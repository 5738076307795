import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, KeepAlive as _KeepAlive, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_router_view = _resolveComponent("router-view");
  const _component_c_config_provider = _resolveComponent("c-config-provider");
  const _component_el_config_provider = _resolveComponent("el-config-provider");
  return _openBlock(), _createBlock(_component_el_config_provider, { locale: _ctx.customLocale }, {
    default: _withCtx(() => [
      _createVNode(_component_c_config_provider, {
        routeTabsOptions: { useRouter: _ctx.useRouter, useRoute: _ctx.useRoute },
        useRouterLink: _ctx.useLink,
        t: _ctx.$t,
        locale: _ctx.customLocale,
        "show-tooltip": ""
      }, {
        default: _withCtx(() => [
          _createVNode(_component_router_view, null, {
            default: _withCtx(({ Component }) => [
              (_openBlock(), _createBlock(_KeepAlive, {
                max: 10,
                include: _ctx.appStore.cacheRoutes
              }, [
                (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
              ], 1032, ["include"]))
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["routeTabsOptions", "useRouterLink", "t", "locale"])
    ]),
    _: 1
  }, 8, ["locale"]);
}
