import { defineComponent as _defineComponent } from "vue";
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = (n) => (_pushScopeId("data-v-6627546e"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "app-search fixed w-full box-border p-10" };
const _hoisted_2 = { class: "title" };
import { useI18nTransform } from "@/hooks/useI18nTransform";
export default /* @__PURE__ */ _defineComponent({
  __name: "index",
  setup(__props) {
    const { t } = useI18nTransform();
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("span", null, _toDisplayString(_unref(t)("\u641C\u7D22")), 1)
        ])
      ]);
    };
  }
});
