import axios, {AxiosRequestConfig} from 'axios'
import {HTTP_CODE,HTTP_STATUS} from '@/enums/request'
import {ElMessage, ElMessageBox} from 'element-plus'
import { user } from '@/store/modules/auth'
import { t, getLanguageCode } from '@/i18n'
import { whiteList } from '@/router'

let showTip = false;
const goLogin = () => {
    if (!showTip) {
        ElMessageBox.close();
    } else {
        return;
    }
    showTip = true;
    ElMessageBox.alert(t('当前账号登录状态异常， 点击确定返回登录页面'), t('提示'), {
        confirmButtonText: t('确定'),
        callback: () => {
            location.href = "./login";
            showTip = false;
        },
    });
};
axios.defaults.baseURL = process.env.VUE_APP_BASE_REQUEST_URL
 
axios.interceptors.request.use(function (config) {
    if (!config.headers) config.headers = {};
    if (process.env.NODE_ENV === 'development') {
        // payload: `{"tenantUserId":53,"tenantId":53,"tenantCode":"ttxdev","authUserId":1,"authUsername":"administrator","profileId":1,"console":true}`
        // payload: `{"tenantUserId":95,"tenantId":95,"tenantCode":"testcybercloud","authUserId":1,"authUsername":"administrator","profileId":1,"console":true}`
        config.headers.payload = `{"tenantUserId":95,"tenantId":95,"tenantCode":"testcybercloud","authUserId":1,"authUsername":"administrator","profileId":1,"console":true}`
        // config.headers.payload = `{"tenantUserId":95,"tenantId":87,"tenantCode":"veryrich","authUserId":1,"authUsername":"administrator","profileId":1,"console":true}`
    }
    if (user.value?.lang) {
        if (!config.headers.lang) config.headers.lang = user.value.lang
    } else {
        if (!config.headers.lang) config.headers.lang = getLanguageCode()
    }
    return config;
}, function (error) {
    return Promise.reject(error);
});

axios.interceptors.response.use(function (response) {
    const {data, status} = response
    const d = data as ResposneData<any>
    if (Number(d.code) === HTTP_CODE.SUCCESS) {
        if (typeof d.data !== 'undefined') {
            return d.data
        }
        return d
    } else {
        if (d.message) {
            ElMessage({type: 'error', message: d.message})
            return Promise.reject(d)
        }
    }
    return d;
}, function (error) {
    // debugger
    if (!whiteList.includes(location.pathname.replace('/console', ''))  && error.response.status === HTTP_STATUS.UNAUTHORIZED) {
        goLogin();
    }
    return Promise.reject(error);
});


export interface ResposneData<T> {
    code: number
    message: string
    data: T
    success: boolean
}

export function get<T>(url: string, params ?: any): Promise<T> {
    return new Promise<T>((resolve, reject) => {
        axios({
            url,
            params
        }).then((t: any) => resolve(t)).catch(reject)
    })
}


export function post<T>(url: string, data ?: any, config?: any): Promise<T> {
    return new Promise<T>((resolve, reject) => {
        axios({
            url,
            data,
            method: 'POST',
            ...config,
        }).then((t: any) => resolve(t)).catch(reject)
    })
}

export function remove<T>(url: string, data ?: any): Promise<T> {
    return new Promise<T>((resolve, reject) => {
        axios({
            url,
            data,
            method: 'DELETE'
        }).then((t: any) => resolve(t)).catch(reject)
    })
}


export function http<T>(config: AxiosRequestConfig): Promise<T> {
    return new Promise<T>((resolve, reject) => {
        axios(config).then((t: any) => resolve(t)).catch(reject)
    })
}



