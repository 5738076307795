import { ElMessage } from "element-plus";
import { onBeforeUnmount, onDeactivated } from "vue";
import { getOrderProduct } from '@/api/product'
import { ORDER_STATUS } from "@/enums/status";
import { t } from '@/i18n'
import { getUrlQuery } from "@/utils/helper";
export function usePay() {

  const state = {
    opener: null as null | Window,
    pay: '',
    orderNo: '',
    timer: null as null | number,
    resolve: null as null | Function,
    reject: null as null | Function
  };
 

  const getMessage = (e: MessageEvent) => {
    if (e.data === 'product-pay') {
      window.postMessage({
        type: 'pay',
        pay: state.pay
      }, '/')
    } else if (e.data === 'product-finish') {
      if (!state.orderNo) return
      getOrderProduct(state.orderNo).then(res => {
        console.log('查询订单状态')
      })
    }
  }
  const endMessage = () => {
    if (state.timer) {
      clearInterval(state.timer)
      state.timer = null
      state.orderNo = ''
    }
    window.removeEventListener('message', getMessage)
  }
  // 打开新窗口支付
  const openPay = (orderNo: string, pay: string) => {
 
    if (state.opener) {
      if (!state.opener.closed) {
        ElMessage.error(t('已有打开的支付订单，请先支付，或者关闭后重试'))
        return
      }
    }
     
    // endMessage()
    state.orderNo = orderNo
    state.pay = pay
    state.opener = window.open(location.origin + '/product-pay?t=' + new Date().getTime())
    window.addEventListener('message', getMessage)
    state.timer = setInterval(getPayStatus, 1000) as unknown as number;
    return new Promise((resolve, reject) => {
      state.resolve = resolve
      state.reject = reject
    })
  }
  const getPayStatus = () => {
    if (state.opener?.closed) {
      if (state.reject) state.reject('cancel')
      endMessage()
      return
    }
    if (!state.orderNo) return
    getOrderProduct(state.orderNo).then(res => {
      // console.log('查询订单状态', res)
      if (res?.status === ORDER_STATUS.TRADE_SUCCESS) {
        state.resolve && state.resolve(res)
        endMessage()
      }
    })
  }
  onBeforeUnmount(() => {
    endMessage()
  })
  // onDeactivated(() => {
  //   console.log('onDeactivated')
  // })
  return {
    getMessage,
    endMessage,
    openPay,
  }
}

// 新窗口支付跳转支付宝
export function pay() {
  const w = window.opener || window.top
  w.addEventListener('message', (e: MessageEvent) => {
    if (e.data?.type === 'pay') {
      const div = document.createElement('div')
      div.innerHTML = e.data.pay
      document.body.appendChild(div)
      document.body.querySelector("form")?.submit()
    }
  })
  w.postMessage('product-pay', '/')
}

// 支付完成
export function payFinish() {
  const w = window.opener || window.top
  w.postMessage('product-finish')
  setTimeout(() => {
    window.close()
  }, 200)
}