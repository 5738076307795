import {post} from '@/utils/request'


// 区域列表
export function regionList(pid = 0) {
    return post('/region/list', {
        pid
    })
}


