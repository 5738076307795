export function toFormData(data: any) {
 
  const formData = new FormData();
  for (const k in data) {
    if (Array.isArray(data[k])) {
      data[k].forEach((v: any) => {
        formData.append(k, v);
      });
    } else formData.append(k, data[k]);
  }
  return formData;
}

export function formatFileSize(fileSize: number) {
  if (fileSize < 1024) {
    return fileSize + 'B'
  } else if (fileSize < (1024*1024)) {
    const temp = fileSize / 1024;
    return temp.toFixed(2) + 'KB'
  } else if (fileSize < (1024*1024*1024)) {
    const temp = fileSize / (1024*1024);
    return temp.toFixed(2) + 'MB'
  } else {
    const temp = fileSize / (1024*1024*1024)
    return temp.toFixed(2) + 'GB'
  }
}
export function getUrlQuery(url: string) {
  const index = url.indexOf('?');
  if (index === -1) return null;
  const query = url.substring(index + 1);
  const vars = query.split("&");
  const obj: Record<string, string> = {}
  for (let i = 0;i < vars.length; i++) {
    const pair = vars[i].split("=");
    obj[pair[0]] = pair[1];
  }
  return obj;
}
export function getUrlVariable(url: string, variable: string) {
  const query = getUrlQuery(url);
  if (!query) return false;
  return(query[variable]);
}

export function toUrl(href: string, query: Record<string, string>) {
  const list: string[] = [];
  Object.keys(query).forEach((key: string) => {
    list.push(`${key}=${query[key]}`)
  })
  return href + '?' + list.join('&');
}

export function str2MultLineHtml(str: string) {
  if (!str) return ''
  return str.split('\n').join('<br/>')
}

/**
 * 已知前后文 取中间文本
 * @param str 全文
 * @param start 前文
 * @param end 后文
 * @returns 中间文本 || null
 */
export function getCenterStr(str: string, start: string, end: string) {
  const res = str.match(new RegExp(`${start}(.*?)${end}`))
  return res ? res[1] : ''
}

export function getOptionLabel(options: any, value: string) {
  const i = options.find((i: any) => i.value === value)
  if (i) return i.label
  return ''
}


// 数字转换成中文数字 0 => 零 100 => 一百
export function convertToChinaNum(num: number) {
  const arr1 = ["零", "一", "二", "三", "四", "五", "六", "七", "八", "九"];

  const arr2 = ["", "十", "百", "千", "万", "十", "百", "千", "亿", "十", "百", "千", "万", "十", "百", "千", "亿"]; //可继续追加更高位转换值

  if (!num || isNaN(num)) {
    return "零";
  }

  const english = num.toString().split("");
  let result = "";
  for (let i = 0; i < english.length; i++) {
    const des_i = english.length - 1 - i; //倒序排列设值

    result = arr2[i] + result;

    const arr1_index = english[des_i];

    result = arr1[arr1_index as any] + result;
  }
  result = result.replace(/零(千|百|十)/g, "零").replace(/十零/g, "十");

  result = result.replace(/零+/g, "零");

  result = result.replace(/零亿/g, "亿").replace(/零万/g, "万");

  result = result.replace(/亿万/g, "亿");

  result = result.replace(/零+$/, "");
  result = result.replace(/^一十/g, "十");

  return result;
}

export function json2formData(json: any) {
  const formData = new FormData();
  Object.keys(json).forEach(key => {
    formData.append(key, json[key]);
  })
  return formData;
}

 