import { defineStore } from 'pinia'
import { RegisterForm, LoginForm, AuthAccount } from '@/types/auth'
import { register, login, logout as fetchLogout, getLoginKey } from '@/api/auth'
import { userInfo, getAccountAuth } from '@/api/user'
import { User } from "@/types/user";
import { Tenant } from "@/types/tenant"
import { ref } from 'vue'
import { AUTH_STATUS, ThirdAuthType } from '@/enums/auth';
import { tenantInfo, getCompanyAuth, getDeveloperAuth } from '@/api/tenant'
import { encryptedData } from '@cybercloud/ui/helper-lib';

export interface AuthenticatMap {
  account?: AUTH_STATUS
  company?: AUTH_STATUS
  developer?: AUTH_STATUS
}
export interface ThirdAuthMap {
  [ThirdAuthType.ALIPAY]: boolean
  [ThirdAuthType.WECHAT]: boolean
}

export interface AuthKey {
  loginKey: string
  rsaPublicKey: string
}
export const user = ref();
export const useAuthStore = defineStore('authStore', {
  state: () => ({
    user: null as null | User,
    tenant: null as null | Tenant,
    authenticated: {
      account: undefined as undefined | AUTH_STATUS,
      company: undefined as undefined | AUTH_STATUS,
      developer: undefined as undefined | AUTH_STATUS,
    },
    atuh: {
      account: undefined as undefined | AuthAccount,
    },
    authKey: null as AuthKey | null,
    token: null
  }),

  getters: {
    hasUser(): boolean {
      return this.user !== null
    },
    authenticatMap(): AuthenticatMap {
      return this.authenticated
    },
    thirdAuth(): ThirdAuthMap {
      return {
        [ThirdAuthType.ALIPAY]: this.user?.thirdAuth?.indexOf(ThirdAuthType.ALIPAY) !== -1,
        [ThirdAuthType.WECHAT]: this.user?.thirdAuth?.indexOf(ThirdAuthType.WECHAT) !== -1
      }
    }
  },

  actions: {
    register(formData: RegisterForm) {
      return register(formData).then((d) => {
        return d
      })
    },
    getLoginKey() {
      return getLoginKey().then((res: any) => {
        this.authKey = res
        return res;
      })
    },
    async getUserInfo() {
      // if (this.user) return this.user
      this.user = await userInfo()
      user.value = this.user;
      // this.getAccountAuth()
      return this.user
    },
    async getTenantAuth() {
      return getCompanyAuth().then((res: any) => {
        if ('authStatus' in res) {
          this.authenticated.company = res.authStatus
        } else {
          this.authenticated.company = undefined
        }

        return res
      })
    },
    getUserData() {
      return Promise.all([this.getAccountAuth(), this.getUserInfo()])
    },
    getTenantInfo() {
      return tenantInfo().then((res) => {
        return this.tenant = res
      });
    },
    async getAccountAuth() {
      return getAccountAuth().then((res: any) => {
        if ('authStatus' in res) {
          this.authenticated.account = res.authStatus
        }
        this.atuh.account = res
        return res
      })
    },
    async getDeveloperAuth() {
      return getDeveloperAuth().then((res: any) => {
        if ('authStatus' in res) {
          this.authenticated.developer = res.authStatus
        }
        return res
      })
    },
    login(formData: LoginForm) {
      return login(formData).then((d: any) => {
        this.token = d.token
        return d
      })
    },
    logout() {
      this.user = null
      return fetchLogout().then(() => {
        this.token = null
        this.user = null
      })
    },
    async encrypted(password: string) {
      const authKey = await getLoginKey() as AuthKey;
      const p = authKey?.loginKey + password
      return encryptedData(authKey?.rsaPublicKey, p)
    },
    encryptedData(authKey: AuthKey, str: string) {
      const p = authKey?.loginKey + str
      return encryptedData(authKey?.rsaPublicKey, p)
    }
  }
})
