// 登出
import { http, post } from "@/utils/request";
import { User, UserUpdateEmail, UserUpdatePhone, UserUpdate, UserAlipayAuth, ThirdAuth } from "@/types/user";
import { toFormData } from "@/utils/helper";

export function userInfo() {
    return post<User>('/user/info')
}
export function userUpdate(data: UserUpdate) {
    return post('/user/update', data)
}
export function userUpdateEmail(data: UserUpdateEmail) {
    return post('/user/update/email', data)
}
export function userUpdatePassword(data: any) {
    return post('/user/update/password', data)
}
export function userUpdatePhone(data: UserUpdatePhone) {
    return post('/user/update/phone', data)
}
export function userUpdateHeadUrl(file: File) {
    // return post('/user/update/headUrl', data)
    return http({
        method: 'POST',
        url: '/user/update/headUrl',
        data: toFormData({
            headUrl: file
        }),
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
}

export function getAccountAuth() {
    return post('/tenant/account/auth/get')
}

export function accountAuthCreate(data: unknown) {
    return post('/tenant/account/auth/create', data)
}
export function userAdminTicket(data: unknown) {
    return post('/user/admin/ticket', data)
}
export function getAlipayAuthUrl() {
    return post<UserAlipayAuth>('/auth/alipay/auth/url')
}

export function getAlipayAuthRealname(data: any) {
    return post<UserAlipayAuth>('/user/alipay/auth/real/name', data)
}

export function userThirdAuth(data: ThirdAuth) {
    return post('/user/third/auth/create', data)
}
export function userThirdAuthDelete(type: string) {
    return post('/user/third/auth/delete', { type })
}
