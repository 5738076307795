export enum VERIFY_METHOD_BIZCODE {
    LOGIN = 1,
    REGISTER,
    CHANGE_PHONENO,
    CHANGE_EMIAL
}


export enum VERIFY_METHOD_CODE {
    PHONE = 'PHONE',
    EMAIL = 'EMAIL'
}

export enum AUTH_STATUS {
    SUCCESS = 'success',
    FAIL = 'fail',
    EXAMINE = 'examine'
}

export enum VERIFY_MDIFY_CODE {
    PHONE = 'modifyPhone',
    EMAIL = 'modifyEmail',
    LOGIN = 'login'
}

export enum ThirdAuthType {
    ALIPAY = 'alipay',
    WECHAT = 'wechat'
}