import { defineComponent as _defineComponent } from "vue";
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = (n) => (_pushScopeId("data-v-0af917f4"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "setting-card__header" };
const _hoisted_2 = { class: "flex items-center" };
const _hoisted_3 = { class: "setting-card__content" };
import { useRouter } from "vue-router";
export default /* @__PURE__ */ _defineComponent({
  __name: "CommonCard",
  props: {
    title: {
      type: String,
      default: ""
    },
    back: [Boolean, Function],
    height: [String, Number]
  },
  setup(__props) {
    const props = __props;
    const router = useRouter();
    function handleBack() {
      if (typeof props.back === "function") {
        props.back();
        return;
      }
      router.back();
    }
    return (_ctx, _cache) => {
      const _component_SvgIcon = _resolveComponent("SvgIcon");
      return _openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["setting-card", { full: __props.height === "100%" }])
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            __props.back ? (_openBlock(), _createBlock(_component_SvgIcon, {
              key: 0,
              "icon-class": "Z_fanhui",
              class: "setting-card-back",
              onClick: _cache[0] || (_cache[0] = ($event) => handleBack())
            })) : _createCommentVNode("", true),
            _renderSlot(_ctx.$slots, "header", {}, () => [
              _createTextVNode(_toDisplayString(props.title), 1)
            ])
          ]),
          _renderSlot(_ctx.$slots, "header-right")
        ]),
        _createElementVNode("div", _hoisted_3, [
          _renderSlot(_ctx.$slots, "default")
        ])
      ], 2);
    };
  }
});
