export default function getEPLocale(_t: Function) {
  return {
    name: "zh-cn",
    el: {
      colorpicker: {
        confirm: _t("确定"),
        clear: _t("清空"),
      },
      datepicker: {
        now: _t("此刻"),
        today: _t("今天"),
        cancel: _t("取消"),
        clear: _t("清空"),
        confirm: _t("确定"),
        selectDate: _t("选择日期"),
        selectTime: _t("选择时间"),
        startDate: _t("开始日期"),
        startTime: _t("开始时间"),
        endDate: _t("结束日期"),
        endTime: _t("结束时间"),
        prevYear: _t("前一年"),
        nextYear: _t("后一年"),
        prevMonth: _t("上个月"),
        nextMonth: _t("下个月"),
        year: _t("年"),
        month1: _t("1 月"),
        month2: _t("2 月"),
        month3: _t("3 月"),
        month4: _t("4 月"),
        month5: _t("5 月"),
        month6: _t("6 月"),
        month7: _t("7 月"),
        month8: _t("8 月"),
        month9: _t("9 月"),
        month10: _t("10 月"),
        month11: _t("11 月"),
        month12: _t("12 月"),
        weeks: {
          sun: _t("周日"),
          mon: _t("周一"),
          tue: _t("周二"),
          wed: _t("周三"),
          thu: _t("周四"),
          fri: _t("周五"),
          sat: _t("周六"),
        },
        months: {
          jan: _t("一月"),
          feb: _t("二月"),
          mar: _t("三月"),
          apr: _t("四月"),
          may: _t("五月"),
          jun: _t("六月"),
          jul: _t("七月"),
          aug: _t("八月"),
          sep: _t("九月"),
          oct: _t("十月"),
          nov: _t("十一月"),
          dec: _t("十二月"),
        },
      },
      select: {
        loading: _t("加载中"),
        noMatch: _t("无匹配数据"),
        noData: _t("无数据"),
        placeholder: _t("请选择"),
      },
      cascader: {
        noMatch: _t("无匹配数据"),
        loading: _t("加载中"),
        placeholder: _t("请选择"),
        noData: _t("暂无数据"),
      },
      pagination: {
        goto: _t("前往"),
        pagesize: _t("条/页"),
        total: _t("共 {total} 条"),
        pageClassifier: _t("页"),
        deprecationWarning: _t("你使用了一些已被废弃的用法，请参考 el-pagination 的官方文档"),
      },
      messagebox: {
        title: _t("提示"),
        confirm: _t("确定"),
        cancel: _t("取消"),
        error: _t("输入的数据不合法!"),
      },
      upload: {
        deleteTip: _t("按 delete 键可删除"),
        delete: _t("删除"),
        preview: _t("查看图片"),
        continue: _t("继续上传"),
      },
      table: {
        emptyText: _t("暂无数据"),
        confirmFilter: _t("筛选"),
        resetFilter: _t("重置"),
        clearFilter: _t("全部"),
        sumText: _t("合计"),
      },
      tree: {
        emptyText: _t("暂无数据"),
      },
      transfer: {
        noMatch: _t("无匹配数据"),
        noData: _t("无数据"),
        titles: [_t("列表 1"), _t("列表 2")],
        filterPlaceholder: _t("请输入搜索内容"),
        noCheckedFormat: _t("共 {total} 项"),
        hasCheckedFormat: _t("已选 {checked}/{total} 项"),
      },
      image: {
        error: _t("加载失败"),
      },
      pageHeader: {
        title: _t("返回"),
      },
      popconfirm: {
        confirmButtonText: _t("确定"),
        cancelButtonText: _t("取消"),
      },
    },
  };
}
