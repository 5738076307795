import { defineStore } from 'pinia'
import { getNoticeUnreadCount } from '@/api/notice'
export const useAppStore = defineStore('appStore', {
  state: () => ({
    noticeCount: 0,
    cacheRoutes: [] as string[],
  }),
  actions: {
    init() {
      this.getNoticeUnreadCount()
    },
    getNoticeUnreadCount() {
      getNoticeUnreadCount().then((res) => {
        this.noticeCount = res.count || 0
      })
    },
    addCacheRoute(name: string) {
      if (!this.cacheRoutes.includes(name)) {
        this.cacheRoutes.push(name)
      }
    },
    removeCacheRoute(name: string) {
      const index = this.cacheRoutes.findIndex(i => i === name)
      if (index !== -1) {
        this.cacheRoutes.splice(index, 1)
      }
    },
    clearCacheRoute() {
      this.cacheRoutes = []
    }
  }
})