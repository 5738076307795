import { defineComponent as _defineComponent } from "vue";
import { createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
import _imports_0 from "@/assets/images/logo.png";
import _imports_1 from "@/assets/images/auth_banner1.jpg";
const _withScopeId = (n) => (_pushScopeId("data-v-064fbb1d"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "regsiter-page overflow-hidden w-screen h-screen flex" };
const _hoisted_2 = { class: "left box-border xl:w-6/12 h-full flex flex-col justify-center items-center w-full" };
const _hoisted_3 = {
  class: "logo",
  style: { "margin-top": "-166px", "margin-bottom": "80px" }
};
const _hoisted_4 = {
  class: "form-panel",
  style: { "width": "500px" }
};
const _hoisted_5 = { class: "title pb-8 font-medium text-2xl" };
const _hoisted_6 = { key: 0 };
const _hoisted_7 = { class: "pt-2" };
const _hoisted_8 = { class: "font-medium" };
const _hoisted_9 = { class: "pt-2 text-sm text-gray-500" };
const _hoisted_10 = { class: "tag" };
const _hoisted_11 = { class: "pt-6" };
const _hoisted_12 = /* @__PURE__ */ _withScopeId(() => /* @__PURE__ */ _createElementVNode("div", { id: "nc" }, null, -1));
const _hoisted_13 = { class: "footer pt-6 text-sm" };
const _hoisted_14 = { class: "pl-1" };
const _hoisted_15 = { class: "title pb-8 font-medium text-2xl" };
const _hoisted_16 = { class: "pb-6" };
const _hoisted_17 = { class: "text-sm" };
const _hoisted_18 = { class: "tag p-1 bg-gray-200 rounded-lg" };
const _hoisted_19 = { class: "text-sm" };
const _hoisted_20 = /* @__PURE__ */ _withScopeId(() => /* @__PURE__ */ _createElementVNode("div", { id: "nc" }, null, -1));
const _hoisted_21 = { class: "footer pt-6 text-sm" };
const _hoisted_22 = { class: "pl-1" };
const _hoisted_23 = /* @__PURE__ */ _withScopeId(() => /* @__PURE__ */ _createElementVNode("div", { class: "right relative box-border w-6/12 flex-1 bg-cover bg-right bg-no-repeat bg-center justify-center items-center flex hidden xl:flex" }, [
  /* @__PURE__ */ _createElementVNode("div", {
    class: "banner-panel w-10/12 h-full flex items-center",
    style: { "background-color": "#e8f2fd", "width": "85%" }
  }, [
    /* @__PURE__ */ _createElementVNode("img", {
      src: _imports_1,
      class: "absolute",
      style: { "right": "7.5%", "width": "95%" }
    })
  ])
], -1));
import { useRoute, useRouter } from "vue-router";
import { onMounted, reactive, computed, ref, watch } from "vue";
import { ElMessage } from "element-plus";
import { useRouterHelpers } from "@/hooks/useRouterHelpers";
import { useCountDown } from "@/hooks/useCountDown";
import { VERIFY_METHOD_CODE } from "@/enums/auth";
import { required } from "@/utils/validate";
import {
  verifyMethodList,
  verifyMethodInfo,
  verifySend,
  verify
} from "@/api/auth";
import { useNvc } from "@/hooks/useNvc";
import { useI18nTransform } from "@/hooks/useI18nTransform";
export default /* @__PURE__ */ _defineComponent({
  __name: "verify",
  setup(__props) {
    const { t } = useI18nTransform();
    const { goLogin } = useRouterHelpers();
    const route = useRoute();
    const router = useRouter();
    const state = reactive({
      methods: [],
      currentMethod: "",
      currentAccount: "",
      step: "send",
      verifyCode: "",
      sendLoading: false,
      fetchMethodsLoading: false,
      verifyLoading: false,
      lockSendTimmer: false
    });
    const form = ref(null);
    const rules = {
      verifyCode: [required(t("\u8BF7\u586B\u5199\u9A8C\u8BC1\u7801"), ["blur"])],
      afs: [
        {
          validator: function(r, v, c) {
            if (!reSendCodeNvcState.success)
              return c(new Error(t("\u8BF7\u5148\u8FDB\u884C\u6ED1\u52A8\u9A8C\u8BC1")));
            else
              c();
          }
        }
      ]
    };
    const sendRules = {
      afs: [
        {
          validator: function(r, v, c) {
            if (!sendCodeNvcState.success)
              return c(new Error(t("\u8BF7\u5148\u8FDB\u884C\u6ED1\u52A8\u9A8C\u8BC1")));
            else
              c();
          }
        }
      ]
    };
    const [countDownState, { start: startCountDown, toSecond: countDownToSecond }] = useCountDown(6e4, () => {
      state.lockSendTimmer = false;
    });
    const vid = route.query.vid;
    const from = route.query.from;
    watch(() => form.value, (v) => {
      setCheckFormRef(v);
    });
    function fetchMethodList() {
      state.fetchMethodsLoading = true;
      verifyMethodList({ verifyId: vid }).then((data) => {
        state.methods = data;
      }).finally(() => {
        state.fetchMethodsLoading = false;
      });
    }
    function changeMethod() {
      fetchMethodInfo();
    }
    function fetchMethodInfo() {
      verifyMethodInfo({ verifyMethod: state.currentMethod, verifyId: vid }).then(
        (d) => {
          state.currentAccount = d.account;
        }
      );
    }
    const tip = computed(() => {
      if (state.currentMethod === VERIFY_METHOD_CODE.EMAIL) {
        return t("\u90AE\u7BB1");
      } else if (state.currentMethod === VERIFY_METHOD_CODE.PHONE) {
        return t("\u624B\u673A\u53F7");
      }
      return "";
    });
    const [reSendCodeNvcState, { call: callNvcResend, setFormRef: setCheckFormRef }] = useNvc((nvc) => {
      return verifySend({ verifyMethod: state.currentMethod, verifyId: vid, nvc: { data: nvc } });
    }, {
      success(d) {
        if (d.success) {
          ElMessage({ type: "success", message: t("\u53D1\u9001\u6210\u529F") });
          state.lockSendTimmer = true;
          startCountDown();
        } else
          ElMessage({ type: "error", message: d.message });
      }
    });
    const [sendCodeNvcState, { call: callNvcSendCode, setFormRef }] = useNvc((nvc) => {
      state.sendLoading = true;
      return verifySend({ verifyMethod: state.currentMethod, verifyId: vid, nvc: { data: nvc } }).finally(() => {
        state.sendLoading = false;
      });
    }, {
      success(d) {
        if (d.success) {
          state.step = "check";
          ElMessage({ type: "success", message: t("\u53D1\u9001\u6210\u529F") });
        } else
          ElMessage({ type: "error", message: d.message });
      }
    });
    function resend() {
      if (state.lockSendTimmer) {
        return;
      }
      callNvcResend();
    }
    function sendCode() {
      callNvcSendCode();
    }
    function onVerify() {
      form.value.validate((valid) => {
        if (!valid) {
          return;
        }
        const p = {
          verifyMethod: state.currentMethod,
          verifyId: vid,
          verifyCode: state.verifyCode
        };
        state.verifyLoading = true;
        verify(p).then((d) => {
          if (d.success) {
            if (!from || from === "register") {
              router.replace({ path: "/login" });
            } else if (from === "login") {
              ElMessage({ type: "success", message: t("\u767B\u5F55\u6210\u529F") });
              router.replace({ path: "/apps" });
            }
          } else {
            state.verifyCode = "";
          }
        }).finally(() => state.verifyLoading = false);
      });
    }
    onMounted(() => {
      fetchMethodList();
    });
    return (_ctx, _cache) => {
      const _component_el_option = _resolveComponent("el-option");
      const _component_el_select = _resolveComponent("el-select");
      const _component_el_form_item = _resolveComponent("el-form-item");
      const _component_el_button = _resolveComponent("el-button");
      const _component_svg_icon = _resolveComponent("svg-icon");
      const _component_el_form = _resolveComponent("el-form");
      const _component_el_input = _resolveComponent("el-input");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("img", {
              src: _imports_0,
              onClick: _cache[0] || (_cache[0] = ($event) => _ctx.$router.push("/"))
            })
          ]),
          _createElementVNode("div", _hoisted_4, [
            state.step === "send" ? (_openBlock(), _createBlock(_component_el_form, {
              key: 0,
              model: {},
              rules: sendRules,
              ref: (v) => _unref(setFormRef)(v)
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_5, _toDisplayString(_unref(t)("\u9A8C\u8BC1")), 1),
                _createVNode(_component_el_form_item, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_select, {
                      modelValue: state.currentMethod,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event) => state.currentMethod = $event),
                      placeholder: _unref(t)("\u9009\u62E9\u9A8C\u8BC1\u65B9\u5F0F"),
                      style: { "width": "100%" },
                      onChange: changeMethod,
                      loading: state.fetchMethodsLoading,
                      "loading-text": _unref(t)("\u6B63\u5728\u52A0\u8F7D\u9A8C\u8BC1\u65B9\u5F0F")
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(state.methods, (m) => {
                          return _openBlock(), _createBlock(_component_el_option, {
                            key: m.verifyMethod,
                            value: m.verifyMethod,
                            label: m.verifyMethodValue
                          }, null, 8, ["value", "label"]);
                        }), 128))
                      ]),
                      _: 1
                    }, 8, ["modelValue", "placeholder", "loading", "loading-text"])
                  ]),
                  _: 1
                }),
                state.currentAccount ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("span", _hoisted_8, _toDisplayString(_unref(t)("\u4F7F\u7528{tip}\u8FDB\u884C\u9A8C\u8BC1", {
                      tip: tip.value
                    })), 1)
                  ]),
                  _createElementVNode("div", _hoisted_9, [
                    _createElementVNode("span", null, _toDisplayString(_unref(t)("\u6211\u4EEC\u5C06\u53D1\u9001\u4E86\u4E00\u6761\u9A8C\u8BC1\u7801\u5230")), 1),
                    _createElementVNode("span", _hoisted_10, _toDisplayString(state.currentAccount), 1)
                  ]),
                  _createElementVNode("div", _hoisted_11, [
                    _createVNode(_component_el_button, {
                      style: { "width": "100%" },
                      size: "large",
                      class: "button-big",
                      type: "primary",
                      onClick: sendCode,
                      loading: state.sendLoading
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_unref(t)("\u53D1\u9001\u9A8C\u8BC1\u7801")), 1)
                      ]),
                      _: 1
                    }, 8, ["loading"])
                  ])
                ])) : _createCommentVNode("", true),
                _unref(sendCodeNvcState).renderedNc ? (_openBlock(), _createBlock(_component_el_form_item, {
                  key: 1,
                  prop: "afs"
                }, {
                  default: _withCtx(() => [
                    _hoisted_12
                  ]),
                  _: 1
                })) : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_13, [
                  _createElementVNode("div", {
                    class: "flot-left flex items-center cursor-pointer",
                    onClick: _cache[2] || (_cache[2] = //@ts-ignore
                    (...args) => _unref(goLogin) && _unref(goLogin)(...args))
                  }, [
                    _createVNode(_component_svg_icon, {
                      "icon-class": "back",
                      style: { "font-size": "17px" }
                    }),
                    _createElementVNode("span", _hoisted_14, _toDisplayString(_unref(t)("\u8FD4\u56DE\u767B\u5F55\u9875")), 1)
                  ])
                ])
              ]),
              _: 1
            }, 512)) : state.step === "check" ? (_openBlock(), _createBlock(_component_el_form, {
              key: 1,
              rules,
              model: state,
              ref_key: "form",
              ref: form,
              onSubmit: _cache[5] || (_cache[5] = _withModifiers(() => {
              }, ["prevent"]))
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_15, _toDisplayString(tip.value) + _toDisplayString(_unref(t)("\u9A8C\u8BC1\u7801\u8F93\u5165")), 1),
                _createElementVNode("ul", _hoisted_16, [
                  _createElementVNode("li", _hoisted_17, [
                    _createTextVNode(_toDisplayString(_unref(t)("\u9A8C\u8BC1\u7801\u5DF2\u7ECF\u53D1\u9001\u5230")) + " ", 1),
                    _createElementVNode("span", _hoisted_18, _toDisplayString(state.currentAccount), 1),
                    _createTextVNode("\u3002 " + _toDisplayString(_unref(t)("\u68C0\u67E5\u4F60\u7684{tip}\u5E76\u5728\u4E0B\u9762\u8F93\u5165\u9A8C\u8BC1\u7801", {
                      tip: tip.value
                    })), 1)
                  ]),
                  _createElementVNode("li", _hoisted_19, [
                    _createTextVNode(_toDisplayString(_unref(t)("\u5982\u679C\u6CA1\u6709\u6536\u5230\u9A8C\u8BC1\u7801\uFF0C\u8BF7\u70B9\u51FB")) + " ", 1),
                    _createVNode(_component_el_button, {
                      type: "text",
                      onClick: resend
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(state.lockSendTimmer ? `${_unref(countDownToSecond)}s` : _unref(t)("\u91CD\u65B0\u53D1\u9001")), 1)
                      ]),
                      _: 1
                    })
                  ])
                ]),
                _unref(reSendCodeNvcState).renderedNc ? (_openBlock(), _createBlock(_component_el_form_item, {
                  key: 0,
                  prop: "afs"
                }, {
                  default: _withCtx(() => [
                    _hoisted_20
                  ]),
                  _: 1
                })) : _createCommentVNode("", true),
                _createVNode(_component_el_form_item, { prop: "verifyCode" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_input, {
                      size: "large",
                      modelValue: state.verifyCode,
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event) => state.verifyCode = $event),
                      modelModifiers: { trim: true },
                      placeholder: _unref(t)("\u8BF7\u8F93\u5165\u9A8C\u8BC1\u7801")
                    }, null, 8, ["modelValue", "placeholder"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_button, {
                  type: "primary",
                  size: "large",
                  class: "button-big",
                  style: { "width": "100%" },
                  loading: state.verifyLoading,
                  onMouseup: onVerify
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_unref(t)("\u9A8C\u8BC1")), 1)
                  ]),
                  _: 1
                }, 8, ["loading"]),
                _createElementVNode("div", _hoisted_21, [
                  _createElementVNode("div", {
                    class: "flot-left flex items-center cursor-pointer",
                    onClick: _cache[4] || (_cache[4] = //@ts-ignore
                    (...args) => _unref(goLogin) && _unref(goLogin)(...args))
                  }, [
                    _createVNode(_component_svg_icon, {
                      "icon-class": "back",
                      style: { "font-size": "17px" }
                    }),
                    _createElementVNode("span", _hoisted_22, _toDisplayString(_unref(t)("\u8FD4\u56DE\u767B\u5F55\u9875")), 1)
                  ])
                ])
              ]),
              _: 1
            }, 8, ["model"])) : _createCommentVNode("", true)
          ])
        ]),
        _hoisted_23
      ]);
    };
  }
});
