import { defineComponent as _defineComponent } from "vue";
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = (n) => (_pushScopeId("data-v-49ba6cb0"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  key: 0,
  class: "layout-menu-item__divided"
};
const _hoisted_2 = ["onClick"];
const _hoisted_3 = { class: "layout-menu-item__view" };
const _hoisted_4 = { class: "layout-menu-item__label" };
import { ref, watch } from "vue";
import { useRouter, useRoute } from "vue-router";
export default /* @__PURE__ */ _defineComponent({
  __name: "menu",
  props: {
    modelValue: {},
    menus: {},
    card: { type: Boolean },
    hasRouter: { type: Boolean },
    size: {}
  },
  emits: ["command", "update:modelValue"],
  setup(__props, { emit }) {
    var _a;
    const props = __props;
    const router = useRouter();
    const route = useRoute();
    const active = ref((_a = props.modelValue) != null ? _a : "");
    watch(
      () => route.path,
      () => {
        if (props.hasRouter) {
          let menu = props.menus.find((i) => route.path.indexOf(i.index) !== -1);
          if (menu) {
            active.value = menu.index;
          }
        }
      },
      {
        immediate: true
      }
    );
    function handleClick(menu) {
      var _a2;
      if (props.hasRouter && menu.index) {
        router.push(menu.index);
      }
      active.value = props.hasRouter ? menu.index : (_a2 = menu.command) != null ? _a2 : "";
      emit("update:modelValue", active.value);
      emit("command", menu.command, menu);
    }
    return (_ctx, _cache) => {
      var _a2;
      const _component_SvgIcon = _resolveComponent("SvgIcon");
      return _openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["layout-menu", { "is-card": _ctx.card, "is-big": props.hasRouter || _ctx.size === "big" }])
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList((_a2 = _ctx.menus) != null ? _a2 : [], (menu) => {
          return _openBlock(), _createElementBlock(_Fragment, {
            key: menu.title
          }, [
            menu.divided ? (_openBlock(), _createElementBlock("div", _hoisted_1)) : _createCommentVNode("", true),
            _createElementVNode("div", {
              class: _normalizeClass(["layout-menu-item", { active: props.hasRouter ? menu.index === active.value : menu.command === active.value }]),
              onClick: ($event) => handleClick(menu)
            }, [
              _createElementVNode("div", _hoisted_3, [
                menu.icon ? (_openBlock(), _createBlock(_component_SvgIcon, {
                  key: 0,
                  class: "layout-menu-item__icon",
                  "icon-class": menu.icon
                }, null, 8, ["icon-class"])) : _createCommentVNode("", true),
                _createElementVNode("span", _hoisted_4, _toDisplayString(menu.title), 1)
              ])
            ], 10, _hoisted_2)
          ], 64);
        }), 128))
      ], 2);
    };
  }
});
