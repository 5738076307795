import { App } from "vue";
export default function install(app: App) {
  app.directive("blur", {
    mounted(el: HTMLElement, data: any) {
      if (data && data.value) {
        el.querySelector(data.value)?.addEventListener("focus", (e: any) => {
          e.target.blur();
        });
        return;
      }
      el.addEventListener("focus", (e: any) => {
        e.target.blur();
      });
    },
  });
}
