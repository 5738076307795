// 登出
import { post, http } from '@/utils/request'
import { Tenant, Company, TenantDesc } from '@/types/tenant'
import { toFormData } from '@/utils/helper'
export function tenantInfo() {
  return post<Tenant>('/tenant/info/get')
}
export function getTenant() {
  return post<TenantDesc>('/tenant/get')
}

export function tenantUpdate(data: Tenant) {
  return post('/tenant/info/update', data)
}

export function getCompanyAuth() {
  return post('/tenant/company/auth/get')
}

export function companyAuthCreate(data: Company) {
  return http({
    method: 'POST',
    url: '/tenant/company/auth/create',
    data: toFormData(data),
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

export function getDeveloperAuth() {
  return post('/tenant/developer/auth/get')
}

export function developerAuthCreate(data: any) {
  return http({
    method: 'POST',
    url: '/tenant/developer/auth/create',
    data: toFormData(data),
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
} 