import { defineComponent as _defineComponent } from "vue";
import { unref as _unref, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = (n) => (_pushScopeId("data-v-0a51b339"), n = n(), _popScopeId(), n);
const _hoisted_1 = /* @__PURE__ */ _withScopeId(() => /* @__PURE__ */ _createElementVNode("div", { id: "login_container" }, null, -1));
import { useAttrs } from "vue";
export default /* @__PURE__ */ _defineComponent({
  __name: "AuthBind",
  props: {
    visible: Boolean,
    title: String
  },
  setup(__props) {
    const props = __props;
    const attrs = useAttrs();
    return (_ctx, _cache) => {
      const _component_CDialog = _resolveComponent("CDialog");
      return _openBlock(), _createBlock(_component_CDialog, _mergeProps(_unref(attrs), {
        visible: props.visible,
        title: props.title,
        width: "600px"
      }), {
        default: _withCtx(() => [
          _hoisted_1
        ]),
        _: 1
      }, 16, ["visible", "title"]);
    };
  }
});
