import { defineComponent as _defineComponent } from "vue";
import { unref as _unref, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = (n) => (_pushScopeId("data-v-5d0f58b6"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  key: 0,
  class: "language"
};
const _hoisted_2 = { class: "language-name text-sm text-gray" };
const _hoisted_3 = { class: "lang-name" };
import { ElDropdown, ElDropdownItem, ElDropdownMenu } from "element-plus";
import { ArrowDown } from "@element-plus/icons-vue";
import { useLang } from "@/hooks/useLang";
import { useAuthStore } from "@/store/modules/auth";
export default /* @__PURE__ */ _defineComponent({
  __name: "index",
  setup(__props) {
    const { currentLang, currentLangName, updateUserLang, changeLang, langList } = useLang();
    const authStore = useAuthStore();
    const handleLang = (lang) => {
      if (authStore.user) {
        updateUserLang(lang);
      } else {
        changeLang(lang);
      }
    };
    return (_ctx, _cache) => {
      const _component_svg_icon = _resolveComponent("svg-icon");
      const _component_el_icon = _resolveComponent("el-icon");
      const _component_SvgIcon = _resolveComponent("SvgIcon");
      return _unref(langList).length > 0 ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_unref(ElDropdown), {
          class: "mr-4",
          onCommand: handleLang,
          "popper-class": "popper-lang"
        }, {
          dropdown: _withCtx(() => [
            _createVNode(_unref(ElDropdownMenu), null, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(langList), (lang) => {
                  return _openBlock(), _createBlock(_unref(ElDropdownItem), {
                    class: "lang-item",
                    key: lang.id,
                    command: lang.code,
                    disabled: lang.code === _unref(currentLang)
                  }, {
                    default: _withCtx(() => [
                      lang.code === _unref(currentLang) ? (_openBlock(), _createBlock(_component_SvgIcon, {
                        key: 0,
                        "icon-class": "duihao1"
                      })) : _createCommentVNode("", true),
                      _createElementVNode("span", _hoisted_3, _toDisplayString(lang.name), 1)
                    ]),
                    _: 2
                  }, 1032, ["command", "disabled"]);
                }), 128))
              ]),
              _: 1
            })
          ]),
          default: _withCtx(() => [
            _createElementVNode("span", _hoisted_2, [
              !_unref(authStore).user ? (_openBlock(), _createBlock(_component_svg_icon, {
                key: 0,
                "icon-class": "yuyan-01"
              })) : _createCommentVNode("", true),
              _createTextVNode(" " + _toDisplayString(_unref(currentLangName)) + " ", 1),
              _createVNode(_component_el_icon, { class: "el-icon--right" }, {
                default: _withCtx(() => [
                  _createVNode(_unref(ArrowDown))
                ]),
                _: 1
              })
            ])
          ]),
          _: 1
        })
      ])) : _createCommentVNode("", true);
    };
  }
});
