var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { defineComponent as _defineComponent } from "vue";
import { createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
import _imports_0 from "@/assets/images/logo.png";
import _imports_1 from "@/assets/images/auth_banner2.jpg";
const _withScopeId = (n) => (_pushScopeId("data-v-b87fc42c"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "regsiter-page overflow-hidden w-screen h-screen flex" };
const _hoisted_2 = { class: "left box-border xl:w-6/12 h-full flex flex-col justify-center items-center w-full relative" };
const _hoisted_3 = {
  class: "logo",
  style: { "margin-top": "-200px", "margin-bottom": "40px" }
};
const _hoisted_4 = {
  key: 0,
  class: "form-panel",
  style: { "width": "500px" }
};
const _hoisted_5 = { class: "title pb-4 font-medium text-xl" };
const _hoisted_6 = { class: "text-sm" };
const _hoisted_7 = { class: "text-sm text-tip" };
const _hoisted_8 = { class: "mt-5" };
const _hoisted_9 = { class: "float-right text-sm" };
const _hoisted_10 = { class: "flex items-center" };
const _hoisted_11 = {
  key: 1,
  class: "register-result text-center",
  style: { "width": "520px" }
};
const _hoisted_12 = { class: "title flex items-center justify-center" };
const _hoisted_13 = { class: "pl-4 text-2xl font-medium" };
const _hoisted_14 = { class: "info mt-10" };
const _hoisted_15 = { class: "tag" };
const _hoisted_16 = {
  key: 0,
  class: "pt-8"
};
const _hoisted_17 = /* @__PURE__ */ _withScopeId(() => /* @__PURE__ */ _createElementVNode("div", { class: "right relative box-border w-6/12 flex-1 bg-cover bg-right bg-no-repeat bg-center justify-center items-center flex hidden xl:flex" }, [
  /* @__PURE__ */ _createElementVNode("div", {
    class: "banner-panel w-10/12 h-full flex items-center",
    style: { "background-color": "#e8f2fd", "width": "100%" }
  }, [
    /* @__PURE__ */ _createElementVNode("img", {
      src: _imports_1,
      class: "absolute",
      style: { "right": "7.5%", "width": "95%" }
    })
  ])
], -1));
import { ref, reactive } from "vue";
import RegisterForm from "./components/forms/register";
import { useRouterHelpers } from "@/hooks/useRouterHelpers";
import { useAuthStore } from "@/store/modules/auth";
import { ElMessage, ElButton } from "element-plus";
import { loginUrl } from "@/api/auth";
import Language from "@/components/Language/index.vue";
import { useI18nTransform } from "@/hooks/useI18nTransform";
export default /* @__PURE__ */ _defineComponent({
  __name: "register",
  setup(__props) {
    const { t } = useI18nTransform();
    const { goLogin } = useRouterHelpers();
    const state = reactive({
      submitLoading: false,
      success: false,
      targetUrl: "",
      email: null
    });
    const footerOptions = ref({
      agreement: false,
      notify: false
    });
    const footOptonsRules = {
      agreement: {
        validator(rule, v, cb) {
          return cb();
        }
      }
    };
    const footerForm = ref(null);
    const form = ref(null);
    const authStore = useAuthStore();
    function getEmailLoginUrl(email) {
      return loginUrl(email).then((d) => {
        state.targetUrl = d.url;
      });
    }
    function onSubmit() {
      return __async(this, null, function* () {
        if (!footerForm.value || !form.value)
          return;
        try {
          const d = yield form.value.getValue();
          state.email = d.email;
          footerForm.value.validate((valid) => {
            if (!valid)
              return;
            state.submitLoading = true;
            d.subscribe = footerOptions.value.notify ? 1 : 0;
            authStore.register(d).then((s) => {
              if (s.success) {
                ElMessage({ type: "success", message: t("\u6CE8\u518C\u6210\u529F") });
                getEmailLoginUrl(d.email).then(() => {
                  state.submitLoading = false;
                  state.success = true;
                });
              } else
                state.submitLoading = false;
            }).catch(() => {
              state.submitLoading = false;
            });
          });
        } catch (e) {
        }
      });
    }
    function handleGoEmail() {
      if (state.targetUrl) {
        window.open(state.targetUrl);
      }
    }
    return (_ctx, _cache) => {
      const _component_el_checkbox = _resolveComponent("el-checkbox");
      const _component_el_form_item = _resolveComponent("el-form-item");
      const _component_el_form = _resolveComponent("el-form");
      const _component_svg_icon = _resolveComponent("svg-icon");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(Language, { class: "setting-language" }),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("img", {
              src: _imports_0,
              onClick: _cache[0] || (_cache[0] = ($event) => _ctx.$router.push("/"))
            })
          ]),
          !state.success ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, _toDisplayString(_unref(t)("\u6CE8\u518C\u6216\u8005\u514D\u8D39\u8BD5\u7528")), 1),
            _createVNode(_unref(RegisterForm), {
              ref_key: "form",
              ref: form
            }, null, 512),
            _createVNode(_component_el_form, {
              ref_key: "footerForm",
              ref: footerForm,
              rules: footOptonsRules,
              model: footerOptions.value
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_form_item, { prop: "agreement" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_checkbox, {
                      modelValue: footerOptions.value.agreement,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event) => footerOptions.value.agreement = $event)
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("span", _hoisted_6, _toDisplayString(_unref(t)("\u540C\u610F\u8BA2\u9605\u534F\u8BAE")), 1)
                      ]),
                      _: 1
                    }, 8, ["modelValue"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_form_item, { prop: "notify" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_checkbox, {
                      modelValue: footerOptions.value.notify,
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event) => footerOptions.value.notify = $event)
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("span", _hoisted_7, _toDisplayString(_unref(t)("\u5E0C\u671B\u63A5\u6536\u6765\u81EACybercloud")) + " " + _toDisplayString(_unref(t)("\u5173\u4E8E\u4EA7\u54C1\u3001\u670D\u52A1\u4EE5\u53CA\u8425\u9500\u6D3B\u52A8\u7684\u4FE1\u606F\u3002\u6211\u53EF\u4EE5\u968F\u65F6\u53D6\u6D88")), 1)
                      ]),
                      _: 1
                    }, 8, ["modelValue"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["model"]),
            _createElementVNode("div", _hoisted_8, [
              _createVNode(_unref(ElButton), {
                loading: state.submitLoading,
                size: "large",
                class: "button-big",
                type: "primary",
                style: { "width": "100%" },
                onClick: onSubmit
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_unref(t)("\u5F00\u59CB\u514D\u8D39\u8BD5\u7528")), 1)
                ]),
                _: 1
              }, 8, ["loading"])
            ]),
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("span", _hoisted_10, [
                _createTextVNode(_toDisplayString(_unref(t)("\u5DF2\u6709\u8D26\u53F7,\u53BB")) + " ", 1),
                _createVNode(_unref(ElButton), {
                  type: "text",
                  onClick: _unref(goLogin)
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_unref(t)("\u767B\u5F55")), 1)
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ])
            ])
          ])) : (_openBlock(), _createElementBlock("div", _hoisted_11, [
            _createElementVNode("div", _hoisted_12, [
              _createVNode(_component_svg_icon, {
                "icon-class": "success",
                style: { "font-size": "40px" }
              }),
              _createElementVNode("span", _hoisted_13, _toDisplayString(_unref(t)("\u606D\u559C\u60A8\u6CE8\u518C\u6210\u529F")), 1)
            ]),
            _createElementVNode("div", _hoisted_14, [
              _createTextVNode(_toDisplayString(_unref(t)("\u6211\u4EEC\u5411\u60A8\u7684\u6CE8\u518C\u90AE\u7BB1\u53D1\u9001\u4E86\u4E00\u6761\u9A8C\u8BC1\u90AE\u4EF6\uFF0C\u8BF7\u767B\u5F55")) + " ", 1),
              _createElementVNode("span", _hoisted_15, _toDisplayString(state.targetUrl || state.email), 1),
              _createTextVNode(" " + _toDisplayString(_unref(t)("\u67E5\u6536")), 1)
            ]),
            state.targetUrl ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
              _createVNode(_unref(ElButton), {
                type: "primary",
                class: "button-big",
                size: "large",
                style: { "width": "100%" },
                onClick: handleGoEmail
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_unref(t)("\u7ACB\u5373\u67E5\u6536\u9A8C\u8BC1\u90AE\u4EF6")), 1)
                ]),
                _: 1
              })
            ])) : _createCommentVNode("", true)
          ]))
        ]),
        _hoisted_17
      ]);
    };
  }
});
