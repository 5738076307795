import { AUTH_STATUS } from '@/enums/auth'
import { t } from '@/i18n'
export function formatAuthStatus(status?: string) {
  if (status === AUTH_STATUS.EXAMINE) return t('审核中')
  if (status === AUTH_STATUS.FAIL) return t('审批失败')
  if (status === AUTH_STATUS.SUCCESS) return t('已认证')
  return t('未认证')
}

export function sendAuthData(url: string) {
  const w = window.opener || window.top
  if (w) {
    w.postMessage({
      type: 'auth',
      data: url
    }, process.env.NODE_ENV === 'development' ? '*' : '/')
    setTimeout(() => {
      window.close()
    }, 200)
    return
  }
}
