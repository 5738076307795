import { productUpgrading } from "@/api/product";
import { useProductStore } from "@/store/modules/product";

/**
 * 全局接口
 */
export default function globalInterface() {
 
}

