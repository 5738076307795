import { defineComponent as _defineComponent } from "vue";
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = (n) => (_pushScopeId("data-v-0e8c35d0"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "user-case pb-16" };
const _hoisted_2 = { class: "m-auto text-center" };
const _hoisted_3 = { class: "title font-semibold text-2xl" };
const _hoisted_4 = /* @__PURE__ */ _withScopeId(() => /* @__PURE__ */ _createElementVNode("div", { class: "sub-title font-semibold text-sm text-gray-300 pt-2" }, " User Case ", -1));
const _hoisted_5 = { class: "case-container" };
const _hoisted_6 = /* @__PURE__ */ _withScopeId(() => /* @__PURE__ */ _createElementVNode("div", { class: "case-filter case-filter__left" }, null, -1));
const _hoisted_7 = /* @__PURE__ */ _withScopeId(() => /* @__PURE__ */ _createElementVNode("div", { class: "case-filter case-filter__right" }, null, -1));
const _hoisted_8 = { class: "case-scrollbox" };
const _hoisted_9 = ["src"];
export default /* @__PURE__ */ _defineComponent({
  __name: "UserCase",
  setup(__props) {
    const cases = [
      [
        require("@/assets/images/adv/u107.png"),
        require("@/assets/images/adv/u91.png"),
        require("@/assets/images/adv/u94.png"),
        require("@/assets/images/adv/u97.png"),
        require("@/assets/images/adv/u101.png"),
        require("@/assets/images/adv/u104.png"),
        require("@/assets/images/adv/u107.png"),
        require("@/assets/images/adv/u91.png"),
        require("@/assets/images/adv/u94.png"),
        require("@/assets/images/adv/u97.png"),
        require("@/assets/images/adv/u101.png"),
        require("@/assets/images/adv/u104.png")
      ],
      [
        require("@/assets/images/adv/u127.png"),
        require("@/assets/images/adv/u110.png"),
        require("@/assets/images/adv/u113.png"),
        require("@/assets/images/adv/u116.png"),
        require("@/assets/images/adv/u121.png"),
        require("@/assets/images/adv/u124.png"),
        require("@/assets/images/adv/u127.png"),
        require("@/assets/images/adv/u110.png"),
        require("@/assets/images/adv/u113.png"),
        require("@/assets/images/adv/u116.png"),
        require("@/assets/images/adv/u121.png"),
        require("@/assets/images/adv/u124.png")
      ],
      [
        require("@/assets/images/adv/u145.png"),
        require("@/assets/images/adv/u130.png"),
        require("@/assets/images/adv/u133.png"),
        require("@/assets/images/adv/u136.png"),
        require("@/assets/images/adv/u139.png"),
        require("@/assets/images/adv/u142.png"),
        require("@/assets/images/adv/u145.png"),
        require("@/assets/images/adv/u130.png"),
        require("@/assets/images/adv/u133.png"),
        require("@/assets/images/adv/u136.png"),
        require("@/assets/images/adv/u139.png"),
        require("@/assets/images/adv/u142.png")
      ]
    ];
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("section", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t("\u5BA2\u6237\u6848\u4F8B")), 1),
          _hoisted_4,
          _createElementVNode("div", _hoisted_5, [
            _hoisted_6,
            _hoisted_7,
            (_openBlock(), _createElementBlock(_Fragment, null, _renderList(cases, (imgs, index) => {
              return _createElementVNode("div", {
                class: "case-content",
                key: index
              }, [
                _createElementVNode("div", _hoisted_8, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(imgs, (img) => {
                    return _openBlock(), _createElementBlock("div", {
                      class: "case-scrollbox-img",
                      key: img
                    }, [
                      _createElementVNode("img", {
                        src: img,
                        alt: ""
                      }, null, 8, _hoisted_9)
                    ]);
                  }), 128))
                ])
              ]);
            }), 64))
          ])
        ])
      ]);
    };
  }
});
