// import { useI18n as useVueI18n } from "vue-i18n";
import i18n, { t } from "@/i18n";
const reportKeys: Array<string> = [];
if (process.env.NODE_ENV === "development") {
  (window as any).__reportKeys = reportKeys;
}
export function useI18nTransform() {
  // const { t } = useVueI18n();
  const transfer = (key: string, d?: any) => {
    if (!key) return key;
    const text = t(key, d);
    if (!text || text === key) {
      if (typeof d === "object") {
        let str = key;
        for (const [key, value] of Object.entries(d)) {
          const reg = new RegExp(`{s*${key}s*}`, "g");
          if (reg.test(str)) {
            str = str.replace(reg, value as string);
          }
        }
        return str;
      }
    }
    return text || key;
  };
  type TypeObejct = {
    [key in string ]: string;
  }
  // 对象数组
  const toObjectArray = <T>(data: T, key: string): T => {
    return (data as any).map((i: any) => {
      i[key] = transfer(i[key]);
      return i;
    }) as T;
  };

  // 表单规则
  const toFormRules = <T extends object>(rules: T): T => {
    const rs = {} as any;
    Object.keys(rules).forEach((k) => {
      if (Array.isArray(rs[k])) rs[k] = toObjectArray((rules as any)[k], "message");
      else if (typeof rs[k] === "object") {
        rs[k] = { ...(rules as any)[k], message: transfer((rules as any)[k].message) };
      } else {
        rs[k] = (rules as any)[k];
      }
    });
    return rs;
  };
  const toFormRule = (rule: any) => {
    if (rule.message) {
      rule.message = transfer(rule.message);
    }
    return rule;
  };
  // 字符串数组
  const toStringArray = (arr: Array<string>) => {
    return arr.map((i) => {
      return transfer(i);
    });
  };
  // 共多少条数据模板
  const toCountTemplate = (count: number | string = 0) => {
    return transfer("共{count}条数据", {
      count,
    });
  };

  setTimeout(() => {
    console.log(reportKeys.join("々"));
  }, 2000);

  return {
    toObjectArray,
    t: transfer,
    toFormRules,
    toFormRule,
    toStringArray,
    toCountTemplate,
  };
}
