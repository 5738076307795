import { reactive, nextTick } from "vue";
import { ElMessage } from "element-plus";
import { configGet } from "@/api/nvc";
import { t } from "@/i18n";

interface NVCParams {
  renderTo?: string;
  success?: Function;
}

let nvcConfig: null | { appKey: string } = null;
export function useNvc(
  handler: Function,
  { renderTo = "nc", success }: NVCParams
) {
  /**
   * 获取 nvc 配置
   */
  function fetchConfig() {
    return configGet().then((response: any) => {
      nvcConfig = { appKey: response.appKey };
    });
  }

  if (nvcConfig === null) {
    const { appKey } = fetchConfig() as any;
    nvcConfig = { appKey };
  }

  let _call: undefined | Function;
  let formRef: any;

  const state = reactive({
    nvc: null as any,
    nvcValue: null as null | string,
    ncValue: null as null | string,
    success: false,
    renderedNc: false,
  });

  function init() {
    // @ts-ignore
    window.AWSC.use("nvc", function (s, module) {
      state.nvc = module.init({
        appkey: nvcConfig?.appKey,
        scene: "nvc_login",
        success(data: string) {
          state.ncValue = data;
          state.success = true;
          if (_call) _call();
          reset();
        },
        fail: function (fc: string, f: string) {
          state.success = false;
        },
        error() {
          reset();
        },
      });
    });
  }

  function getNVCValue(): Promise<string> {
    return new Promise((resolve) => {
      state.nvc?.getNVCValAsync((nvcValue: string) => {
        resolve(nvcValue);
      });
    });
  }

  // 清除表单 ref 的校验
  function clearFormRefAfsValidators() {
    if (formRef && typeof formRef.clearValidate === "function") {
      formRef.clearValidate("afs");
    }
  }

  async function call(...params: Array<any>) {
    if (state.renderedNc && !state.ncValue) {
      ElMessage({ type: "error", message: t("请先完成滑动验证") });
      return;
    }

    _call = () => {
      // eslint-disable-next-line prefer-spread
      const res = handler.apply(
        null,
        [state.ncValue || state.nvcValue].concat(params)
      );
      if (res instanceof Promise) {
        res.then((response) => {
          if (response.bizCode === "100" || response.bizCode === "200") {
            state.success = true;
            // 无痕验证通过
            if (success) {
              success(response);
            }
            reset();
          } else if (response.bizCode === "800" || response.bizCode === "900") {
            // 无痕验证失败，直接拦截
            ElMessage.error({ message: t("验证失败请重试") });
            reset();
          } else if (response.bizCode === "400") {
            // 无痕验证失败，触发二次验证
            // 二次验证码（滑动验证码）配置项设置，详情请见滑动验证集成方式文档
            // 二次验证的appkey，scene，test值及success，fail，error的回调由nvc初始化时决定，请不要在二次验证时传入
            const ncoption = {
              renderTo,
            };
            state.renderedNc = true;
            nextTick(() => {
              const target = document.querySelector("#" + renderTo);
              if (target) {
                const { children } = target;
                if (children.length > 0) {
                  for (const child of children) {
                    target.removeChild(child);
                  }
                }
              }
              state.nvc.getNC(ncoption);
            });
          }
        });
      }
    };

    state.nvcValue = await getNVCValue();

    if (_call) {
      _call();
    }
  }

  function reset() {
    state.nvc.reset();
    state.ncValue = state.nvcValue = null;
    state.success = false;
    state.renderedNc = false;
    clearFormRefAfsValidators();
    init();
  }

  const context = {
    getNVCValue,
    call,
    reset,

    setFormRef(v: any) {
      formRef = v;
    },
  };

  init();

  return [state, context] as [typeof state, typeof context];
}
