import {App} from 'vue'

import AppStoreDialog from '@/components/AppStore/dialog.vue'
import AppFooter from '@/components/AppFooter/index.vue'
import CommonCard from '@/components/Common/CommonCard.vue'
import CommonInfo from '@/components/Common/CommonInfo.vue'
import SvgIcon from '@/components/Icon/index.vue'
export default function install(app: App) {
  app.component('AppStoreDialog' , AppStoreDialog)
  app.component('AppFooter' , AppFooter)
  app.component('SvgIcon', SvgIcon)
  app.component('CommonCard', CommonCard)
  app.component('CommonInfo', CommonInfo)
}
