import { t } from '@/i18n'
export const required = (message?: string, trigger = ['blur']) => ({required: true, message: message || t('必填项') , trigger})


export const email = (message?: string, trigger = ['blur']) => ({type: 'email', message: message || t('请输入合法的邮箱地址'), trigger})


export const phone = (message?: string, trigger = ['blur']) => ({
    pattern: /^1(3|4|5|6|7|8|9)\d{9}$/,
    message: message || t('请输入合法的手机号'),
    trigger
})

export const password = ( ) => (({
    pattern: /^(?![A-Za-z]+$)(?![A-Z\d]+$)(?![A-Z\W]+$)(?![a-z\d]+$)(?![a-z\W]+$)(?![\d\W]+$)\S{8,50}$/,
    message: t('密码长度为8-50位数，包含大小写字母和数字')
}))