import { defineComponent, h } from "vue";
import AppHeader from "./index.vue";
import { useI18nTransform } from "@/hooks/useI18nTransform";
export default defineComponent({
  setup() {
    const { t } = useI18nTransform();
    return () => h(AppHeader, {}, {
      left: [
        h("a", { href: "/solution", class: "action-item" }, t("\u65B9\u6848")),
        h("a", { class: "action-item" }, t("\u5E73\u53F0")),
        h("a", { class: "action-item" }, t("\u5BA2\u6237")),
        h("a", { class: "action-item" }, t("\u5173\u4E8E"))
      ]
    });
  }
});
