var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { defineComponent as _defineComponent } from "vue";
import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, Fragment as _Fragment, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
import _imports_0 from "@/assets/images/logo.png";
import _imports_1 from "@/assets/images/avatar.jpg";
const _withScopeId = (n) => (_pushScopeId("data-v-4181af53"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "app-header flex" };
const _hoisted_2 = { class: "left flex" };
const _hoisted_3 = /* @__PURE__ */ _withScopeId(() => /* @__PURE__ */ _createElementVNode("div", { class: "logo mr-16" }, [
  /* @__PURE__ */ _createElementVNode("a", { href: "/" }, [
    /* @__PURE__ */ _createElementVNode("img", {
      src: _imports_0,
      class: "w-24 mr-6",
      alt: ""
    })
  ])
], -1));
const _hoisted_4 = { class: "actions flex space-x-10 items-center" };
const _hoisted_5 = { class: "right flex items-center" };
const _hoisted_6 = {
  key: 1,
  class: "action-item"
};
const _hoisted_7 = {
  key: 2,
  class: "user-avatar flex items-center"
};
const _hoisted_8 = { class: "app-header-user flex items-center" };
const _hoisted_9 = ["src"];
const _hoisted_10 = {
  key: 1,
  class: "user-img",
  src: _imports_1
};
const _hoisted_11 = { class: "app-header-user__info flex flex-col" };
const _hoisted_12 = { class: "user-name truncate" };
const _hoisted_13 = { class: "user-tenant font-gray-normal text-xs truncate" };
const _hoisted_14 = { class: "user-detail" };
const _hoisted_15 = { class: "user-detail-header" };
const _hoisted_16 = ["src"];
const _hoisted_17 = {
  key: 1,
  class: "user-img",
  src: _imports_1
};
const _hoisted_18 = { class: "user-info" };
const _hoisted_19 = { class: "user-name font-sm truncate" };
const _hoisted_20 = { class: "user-tenant font-gray-normal text-xs truncate" };
import { watch, reactive, ref } from "vue";
import AppSearch from "@/components/AppSearch/index.vue";
import { useRouter, useRoute } from "vue-router";
import { ElMessage, ElMessageBox, ElPopover } from "element-plus";
import { useAuthStore } from "@/store/modules/auth";
import { useRouterHelpers } from "@/hooks/useRouterHelpers";
import { useAppStore } from "@/store/modules/app";
import Language from "@/components/Language/index.vue";
import Menu from "@/components/Layout/menu.vue";
import { useI18nTransform } from "@/hooks/useI18nTransform";
export default /* @__PURE__ */ _defineComponent({
  __name: "index",
  setup(__props) {
    const { t } = useI18nTransform();
    const router = useRouter();
    const route = useRoute();
    const authStore = useAuthStore();
    const { goLogin } = useRouterHelpers();
    const appStore = useAppStore();
    const userPopoverRef = ref();
    const loaded = ref(false);
    const state = reactive({
      hasLogin: authStore.hasUser,
      searchVisible: false,
      userAvatar: true,
      langOptions: [],
      activeMenu: ""
    });
    watch(() => route.path, () => {
      const menu = menus.find((i) => route.path.indexOf("/" + i.command) !== -1);
      if (menu) {
        state.activeMenu = menu.command;
      }
    });
    authStore.getUserInfo().then(() => {
      if (authStore.user) {
        appStore.init();
      }
    }).finally(() => {
      loaded.value = true;
    });
    function toggleSearch() {
      state.searchVisible = !state.searchVisible;
    }
    function logout() {
      return __async(this, null, function* () {
        ElMessageBox.confirm(
          t("\u60A8\u786E\u5B9A\u8981\u9000\u51FA\u767B\u5F55\u5417\uFF1F"),
          t("\u63D0\u793A"),
          {
            confirmButtonText: t("\u786E\u5B9A"),
            cancelButtonText: t("\u53D6\u6D88"),
            type: "warning"
          }
        ).then(() => __async(this, null, function* () {
          yield authStore.logout();
          ElMessage({ type: "success", message: t("\u767B\u51FA\u6210\u529F") });
          setTimeout(() => {
            location.href = "/login";
          }, 400);
        }));
      });
    }
    function openMessage() {
      router.push("/guide/notice");
    }
    function goConsole() {
      router.push("/guide");
    }
    const menus = [
      {
        icon: "zhanghushezhi",
        title: t("\u8D26\u6237\u8BBE\u7F6E"),
        command: "account"
      },
      {
        icon: "renzhengshezhi",
        title: t("\u8BA4\u8BC1\u8BBE\u7F6E"),
        command: "setting"
      },
      {
        icon: "dingdan",
        title: t("\u8BA2\u5355\u7BA1\u7406"),
        command: "order"
      },
      {
        icon: "chanpinguanli1",
        title: t("\u4EA7\u54C1\u7BA1\u7406"),
        command: "product"
      },
      {
        icon: "a-rizhiguanli1",
        title: t("\u65E5\u5FD7\u7BA1\u7406"),
        command: "log"
      },
      {
        icon: "shenhe",
        title: t("\u589E\u503C\u670D\u52A1\u5BA1\u6279"),
        command: "approval"
      },
      {
        icon: "tuichu",
        title: t("\u9000\u51FA\u767B\u5F55"),
        command: "logout",
        divided: true
      }
    ];
    function handleMenuCommand(command) {
      var _a;
      (_a = userPopoverRef.value) == null ? void 0 : _a.hide();
      switch (command) {
        case "logout":
          logout();
          break;
        case "login":
          goLogin();
          break;
        case "account":
          router.push({
            path: "/account/user"
          });
          break;
        case "setting":
          router.push({
            path: "/setting/account"
          });
          break;
        case "order":
          router.push({
            path: "/order/list"
          });
          break;
        case "product":
          router.push({
            path: "/product/purchase"
          });
          break;
        case "log":
          router.push({
            path: "/log/login"
          });
          break;
        case "approval":
          router.push({
            path: "/approval/value-added"
          });
      }
    }
    watch(() => authStore.hasUser, () => {
      setTimeout(() => {
        state.hasLogin = authStore.hasUser;
      }, 200);
    }, { immediate: true });
    return (_ctx, _cache) => {
      const _component_svg_icon = _resolveComponent("svg-icon");
      const _directive_tooltip = _resolveDirective("tooltip");
      return _openBlock(), _createElementBlock("header", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _hoisted_3,
          _createElementVNode("div", _hoisted_4, [
            _renderSlot(_ctx.$slots, "left")
          ])
        ]),
        _createElementVNode("div", _hoisted_5, [
          loaded.value && state.hasLogin ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _unref(route).path === "/" ? (_openBlock(), _createElementBlock("span", {
              key: 0,
              class: "console-box flex items-center cursor-pointer mr-4 text-gray text-sm",
              onClick: goConsole
            }, _toDisplayString(_unref(t)("\u63A7\u5236\u53F0")), 1)) : _createCommentVNode("", true),
            _createVNode(Language, { class: "setting-language" }),
            _createVNode(_component_svg_icon, {
              "icon-class": "icon-notice",
              class: _normalizeClass(["cursor-pointer mr-4", { "notice-count": _unref(appStore).noticeCount > 0 }]),
              style: { "font-size": "24px" },
              onClick: openMessage
            }, null, 8, ["class"])
          ], 64)) : _createCommentVNode("", true),
          loaded.value && !state.hasLogin ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createVNode(Language, { class: "setting-language" }),
            _createElementVNode("button", {
              class: "button-try",
              onClick: _cache[0] || (_cache[0] = ($event) => _ctx.$router.push("/register"))
            }, _toDisplayString(_unref(t)("\u514D\u8D39\u8BD5\u7528")), 1)
          ])) : (_openBlock(), _createElementBlock("div", _hoisted_7, [
            _createVNode(_unref(ElPopover), {
              trigger: "click",
              "show-arrow": false,
              ref_key: "userPopoverRef",
              ref: userPopoverRef,
              width: "280px",
              "popper-style": "padding: 0"
            }, {
              reference: _withCtx(() => {
                var _a, _b, _c, _d, _e, _f;
                return [
                  _createElementVNode("div", _hoisted_8, [
                    ((_a = _unref(authStore).user) == null ? void 0 : _a.headUrl) && state.userAvatar ? (_openBlock(), _createElementBlock("img", {
                      key: 0,
                      class: "user-img",
                      src: (_b = _unref(authStore).user) == null ? void 0 : _b.headUrl,
                      alt: "",
                      onError: _cache[1] || (_cache[1] = ($event) => state.userAvatar = false)
                    }, null, 40, _hoisted_9)) : (_openBlock(), _createElementBlock("img", _hoisted_10)),
                    _createElementVNode("div", _hoisted_11, [
                      _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_12, [
                        _createTextVNode(_toDisplayString(((_c = _unref(authStore).user) == null ? void 0 : _c.name) || ((_d = _unref(authStore).user) == null ? void 0 : _d.username)) + "@" + _toDisplayString((_e = _unref(authStore).user) == null ? void 0 : _e.tenantNo), 1)
                      ])), [
                        [_directive_tooltip]
                      ]),
                      _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_13, [
                        _createTextVNode(_toDisplayString((_f = _unref(authStore).user) == null ? void 0 : _f.companyName), 1)
                      ])), [
                        [_directive_tooltip]
                      ])
                    ])
                  ])
                ];
              }),
              default: _withCtx(() => {
                var _a, _b, _c, _d;
                return [
                  _createElementVNode("div", _hoisted_14, [
                    _createElementVNode("div", _hoisted_15, [
                      ((_a = _unref(authStore).user) == null ? void 0 : _a.headUrl) && state.userAvatar ? (_openBlock(), _createElementBlock("img", {
                        key: 0,
                        class: "user-img",
                        src: (_b = _unref(authStore).user) == null ? void 0 : _b.headUrl,
                        alt: "",
                        onError: _cache[2] || (_cache[2] = ($event) => state.userAvatar = false)
                      }, null, 40, _hoisted_16)) : (_openBlock(), _createElementBlock("img", _hoisted_17)),
                      _createElementVNode("div", _hoisted_18, [
                        _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_19, [
                          _createTextVNode(_toDisplayString((_c = _unref(authStore).user) == null ? void 0 : _c.name), 1)
                        ])), [
                          [_directive_tooltip]
                        ]),
                        _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_20, [
                          _createTextVNode(_toDisplayString((_d = _unref(authStore).user) == null ? void 0 : _d.companyName), 1)
                        ])), [
                          [_directive_tooltip]
                        ])
                      ])
                    ]),
                    _createVNode(Menu, {
                      menus,
                      modelValue: state.activeMenu,
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event) => state.activeMenu = $event),
                      onCommand: handleMenuCommand
                    }, null, 8, ["modelValue"])
                  ])
                ];
              }),
              _: 1
            }, 512)
          ]))
        ]),
        state.searchVisible ? (_openBlock(), _createBlock(AppSearch, { key: 0 })) : _createCommentVNode("", true)
      ]);
    };
  }
});
