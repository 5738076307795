import { defineComponent as _defineComponent } from "vue";
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
import _imports_0 from "@/assets/images/qr_code/ttx_wechat.png";
const _withScopeId = (n) => (_pushScopeId("data-v-5b8e3012"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "app-footer py-14" };
const _hoisted_2 = { class: "container m-auto flex" };
const _hoisted_3 = {
  class: "left",
  style: { "padding-right": "10%" }
};
const _hoisted_4 = { class: "pb-2" };
const _hoisted_5 = { class: "pb-4 flex" };
const _hoisted_6 = { class: "call-left flex flex-col items-center relative pr-4" };
const _hoisted_7 = /* @__PURE__ */ _withScopeId(() => /* @__PURE__ */ _createElementVNode("img", {
  src: _imports_0,
  class: "border",
  style: { "width": "110px" },
  alt: ""
}, null, -1));
const _hoisted_8 = { class: "pt-1 foll-wx" };
const _hoisted_9 = { class: "pl-4" };
const _hoisted_10 = { class: "flex items-center" };
const _hoisted_11 = { class: "pr-2" };
const _hoisted_12 = { class: "pt-12 font-light" };
const _hoisted_13 = /* @__PURE__ */ _withScopeId(() => /* @__PURE__ */ _createElementVNode("div", { class: "pt-2 font-medium" }, " 400-552-5566 ", -1));
const _hoisted_14 = { class: "right flex justify-between flex-1 mx-30 pl-20" };
const _hoisted_15 = { class: "group" };
const _hoisted_16 = { class: "title" };
const _hoisted_17 = { class: "group" };
const _hoisted_18 = { class: "title" };
const _hoisted_19 = { class: "group" };
const _hoisted_20 = { class: "title" };
const _hoisted_21 = { class: "group" };
const _hoisted_22 = { class: "title" };
import { reactive } from "vue";
import Copyright from "@/components/Copyright/index.vue";
import { useI18nTransform } from "@/hooks/useI18nTransform";
export default /* @__PURE__ */ _defineComponent({
  __name: "index",
  setup(__props) {
    const { t } = useI18nTransform();
    const state = reactive({
      currentCRCode: ""
    });
    function hoverQrCode(type) {
      state.currentCRCode = type;
    }
    return (_ctx, _cache) => {
      const _component_svg_icon = _resolveComponent("svg-icon");
      return _openBlock(), _createElementBlock(_Fragment, null, [
        _createElementVNode("footer", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, _toDisplayString(_unref(t)("\u8054\u7CFB\u4FE1\u606F")), 1),
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  _hoisted_7,
                  _createElementVNode("div", _hoisted_8, _toDisplayString(_unref(t)("\u5173\u6CE8\u5FAE\u4FE1")), 1)
                ]),
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("div", _hoisted_10, [
                    _createElementVNode("span", _hoisted_11, _toDisplayString(_unref(t)("\u5173\u6CE8\u5FAE\u535A")), 1),
                    _createVNode(_component_svg_icon, {
                      "icon-class": "weibo",
                      style: { "color": "#898a8a", "font-size": "25px" }
                    })
                  ]),
                  _createElementVNode("div", _hoisted_12, _toDisplayString(_unref(t)("\u901A\u5929\u6653\u670D\u52A1\u70ED\u7EBF")), 1),
                  _hoisted_13
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_14, [
              _createElementVNode("div", _hoisted_15, [
                _createElementVNode("div", _hoisted_16, _toDisplayString(_unref(t)("\u8D44\u6E90")), 1)
              ]),
              _createElementVNode("div", _hoisted_17, [
                _createElementVNode("div", _hoisted_18, _toDisplayString(_unref(t)("\u5173\u4E8E")), 1)
              ]),
              _createElementVNode("div", _hoisted_19, [
                _createElementVNode("div", _hoisted_20, _toDisplayString(_unref(t)("\u94FE\u63A5")), 1)
              ]),
              _createElementVNode("div", _hoisted_21, [
                _createElementVNode("div", _hoisted_22, _toDisplayString(_unref(t)("\u516C\u53F8")), 1)
              ])
            ])
          ])
        ]),
        _createVNode(Copyright)
      ], 64);
    };
  }
});
