import { getAlipayAuthUrl, userThirdAuth, userThirdAuthDelete } from '@/api/user'
import { getUrlVariable } from '@/utils/helper'
import { ElMessage, ElMessageBox } from 'element-plus'
import { onBeforeUnmount, ref } from 'vue'
import { ThirdAuthType } from '@/enums/auth'
import { useAuthStore } from '@/store/modules/auth'
import { loginByThird } from '@/api/auth'
import { useI18nTransform } from '@/hooks/useI18nTransform'

export function useThirdAuth(callback?: Function) {
  const { t } = useI18nTransform()
  const loading = ref(false)
  const authStore = useAuthStore()
  const state = {
    opener: null as Window | null,
    callback: callback,
    wxCallback: undefined as Function | undefined,
    type: '',
    time: 0,
    isStart: false,
    authType: '',
    resolve: null as null | Function,
    reject: null as null | Function,
    loading: false
  }
  const host = process.env.NODE_ENV === "development" ? "http://home.cybercloud.loghub.com" : window.location.origin;
  const getAuthData = (e: MessageEvent) => {
    const t = e.data.data.split('?')
    const type = t[0].replace('/auth/', '');
    let code = ''
    if (type === ThirdAuthType.ALIPAY) {
      code = getUrlVariable(e.data.data, 'auth_code') as string;
    } else {
      code = getUrlVariable(e.data.data, 'code') as string;
    }
    return {
      code,
      type
    };
  }
  const handlers = {
    bind(e: MessageEvent) {
      setTimeout(() => {
        const { type, code } = getAuthData(e)
        userThirdAuth({
          code: code,
          type: type
        }).then(() => {
          ElMessage.success(t('绑定成功'))
          authStore.getUserData()
          if (state.resolve) {
            if(state.resolve(e.data.data)) return
          }
        }).catch(() => {
          if (state.resolve) {
            if(state.resolve(e.data.data)) return
          }
        })
      }, 1000)
    },
    login(e: MessageEvent) {
      const { type, code } = getAuthData(e)
      loginByThird(type, code).then((res) => {
        if (state.resolve) {
          if(state.resolve(e.data.data)) return
        }
      }).catch(() => {
        if (state.reject) {
          if(state.reject(e.data.data)) return
        }
      })
    },
    certification(e: MessageEvent) {
      state.callback && state.callback(e.data.data)
    }
  }
  startMessage()
  onBeforeUnmount(() => {
    endMessage()
  })
  function startMessage() {
    if (state.isStart) return
    state.isStart = true
    window.addEventListener('message', getMessage)
  }

  function endMessage() {
    window.removeEventListener('message', getMessage)
    state.isStart = false
  }
  function getMessage(e: MessageEvent) {
    if (e.data?.type === 'auth') {
      endMessage()
      if (state.authType === 'bind') {
        handlers.bind(e)
      } else if (state.authType === 'certification') {
        handlers.certification(e)
      } else if (state.authType === 'login') {
        handlers.login(e)
      }
    }
  }
  // 实名认证
  function certification(url: string, cb: Function) {
    state.authType = 'certification'
    state.opener = window.open(url)
    startMessage()
    state.callback = cb
  }
  function loginThird(type: ThirdAuthType) {
    state.authType = 'login'
    if (type === ThirdAuthType.ALIPAY) {
      return bindAlipay()
    } else if (type === ThirdAuthType.WECHAT) {
      return bindWechat()
    }
  }
  function bindAccount(type: ThirdAuthType) {
    state.authType = 'bind'
    if (type === ThirdAuthType.ALIPAY) {
      return bindAlipay()
    } else if (type === ThirdAuthType.WECHAT) {
      return bindWechat()
    }
  }
  function bindPromise() {
    return new Promise((resolve: Function, reject: Function) => {
      state.resolve = resolve
      state.reject = reject
    })
  }
  function unbindAccount(type: ThirdAuthType, before?: Function) {
    return ElMessageBox.confirm(t('您确定解绑此账号吗？'), t('解绑第三方账号'), {
      confirmButtonText: t('解绑'),
      cancelButtonText: t('取消'),
      type: 'warning',
    }).then(() => {
      before && before()
      loading.value = true
      return userThirdAuthDelete(type).then(() => {
        ElMessage.success(t('解绑成功'))
        authStore.getUserData()
        if (callback) callback()
      }).finally(() => {
        loading.value = false
      })
    })
  }
  function bindWechat() {
    const redirect_uri = encodeURIComponent(host + '/auth/' + ThirdAuthType.WECHAT)
    const appid = process.env.VUE_APP_WECHAT_APPID
    const w = window as any
    // state.wxCallback = callback
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const obj = new w.WxLogin({
      self_redirect: true,
      id: "login_container", 
      appid: appid, 
      scope: "snsapi_login", 
      redirect_uri: redirect_uri,
      state: new Date().getTime(),
      style: "",
      href: ""
    });
    startMessage()
    return bindPromise()
  }
  function bindAlipay() {
    if (loading.value) return
    loading.value = true
    return getAlipayAuthUrl().then((res) => {
      state.type = ThirdAuthType.ALIPAY
      if (state.opener) {
        state.opener = null
        endMessage()
      }
      loading.value = false
      state.opener = window.open(res.url)
      startMessage()
      return bindPromise()
    }).finally(() => {
      loading.value = false
    })
  }
  function unBindAlipay(callback?: Function) {
    if (loading.value) return
    return ElMessageBox.confirm(t('您确定解绑此账号吗？'), t('提示')).then(() => {
      loading.value = true
      return userThirdAuthDelete(ThirdAuthType.ALIPAY).then(() => {
        ElMessage.success(t('解绑成功'))
        authStore.getUserData()
        if (callback) callback()
      }).finally(() => {
        loading.value = false
      })
    })
 
  }
  function wechatLogin(callback?: Function) {
    const redirect_uri = encodeURIComponent(host + '/auth/' + ThirdAuthType.WECHAT)
    const appid = process.env.VUE_APP_WECHAT_APPID
    const w = window as any
    state.wxCallback = callback
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const obj = new w.WxLogin({
      self_redirect: true,
      id: "login_container", 
      appid: appid, 
      scope: "snsapi_login", 
      redirect_uri: redirect_uri,
      state: new Date().getTime(),
      style: "",
      href: ""
    });
  }
  return {
    bindAlipay,
    unBindAlipay,
    bindWechat,
    bindAccount,
    unbindAccount,
    wechatLogin,
    certification,
    loginThird,
    loading
  }
}