import { createI18n, useI18n } from "vue-i18n";
import zhCn from "./zh-cn";
import { useModule } from "./useModule";

const i18n = createI18n({
  locale: "zh-cn",
  fallbackLocale: "zh-cn",
  legacy: false,
  globalInjection: true,
  // silentFallbackWarn: true,
  silentTranslationWarn: false,
  warnHtmlMessage: false,
  fallbackWarn: false,
  missing: () => {},
  messages: {
    "zh-cn": zhCn,
  },
});
const { loadModule } = useModule(i18n as any);
const LANGUAGE_CODE = "language_name";
// 加载本地语言
export function loadLanguage(lang: string) {
  return loadModule(lang);
}
// 设置当前语言并缓存
export function changeLanguage(lang = "zh-cn") {
  // @ts-ignore
  i18n.global.locale.value = lang;
  setLanguageCode(lang);
}

// 获取当前语言编码
export function getLanguageCode() {
  return localStorage.getItem(LANGUAGE_CODE) || 'zh-cn';
}
// 设置当前语言
export function setLanguageCode(lang: string) {
  localStorage.setItem(LANGUAGE_CODE, lang);
}
export const t = (key: string, d?: any) => {
  if (!key) return key;
  const text = i18n.global.t(key, d);
  if (!text || text === key) {
    if (typeof d === "object") {
      let str = key;
      for (const [key, value] of Object.entries(d)) {
        const reg = new RegExp(`{s*${key}s*}`, "g");
        if (reg.test(str)) {
          str = str.replace(reg, value as string);
        }
      }
      return str;
    }
  }
  return text || key;
};
export default i18n;
export { useI18n, loadModule };
